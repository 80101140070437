import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { modalAction } from "../../redux/features/modals/actionModals";
import { useAppDispatch, useAppSelector } from "../../redux/store/store";
import { theme } from "../../styles/theme";
import PortalLayout from "../PortalLayout/PortalLayout";

const StAlert = styled.div`
  width: 444px;
  padding: 32px;
  background: ${theme.colors.bgWhite};
  border: 1px solid #dcdcdc;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
`;

const StText = styled.p`
  margin-bottom: 24px;
  ${theme.font.medium.fontSize18};
`;

const StBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StAlertButton = styled.button`
  width: 120px;
  height: 40px;
  border-radius: 4px;
  background: #7fc41c;
  border-radius: 4px;
  color: ${theme.colors.bgWhite};
  ${theme.font.medium.fontSize18};
`;

const ActionAlert = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { text, path } = useAppSelector((state) => state.actionModal);

  const modalClose = () => {
    dispatch(modalAction.closeModal());
    navigate(`${path}`);
  };

  return (
    <PortalLayout zIndex={10}>
      <StAlert>
        <StText>{text}</StText>
        <StBtnContainer>
          <StAlertButton onClick={modalClose}>확인</StAlertButton>
        </StBtnContainer>
      </StAlert>
    </PortalLayout>
  );
};

export default ActionAlert;
