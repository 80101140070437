import React, { useEffect, useState } from "react";
import ContainerLayout from "../../../components/Layout/ContainerLayout/ContainerLayout";
import { theme } from "../../../styles/theme";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/store/store";
import editUserInfo from "../../../services/user/editUserInfo";
import userInfo from "../../../services/user/userInfo";
import { StPageTitle } from "../../../components/CommonStyled/ComonStyled";
import { alertAction } from "../../../redux/features/modals/alertModal";

interface Button {
  bg: string;
  margin?: string;
}

interface ListInput {
  bg: string;
  color: string;
}

const StUserInfo = styled.div`
  width: 1560px;
  padding: 32px 40px 40px 32px;
  border-radius: 12px;
  background: ${theme.colors.bgWhite};

  & > h3 {
    margin-bottom: 16px;
    ${theme.font.bold.fontSize18};
  }
`;

const StInfoList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;

  & > li:not(:last-of-type) {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    & > label {
      width: 80px;
      height: 22px;
      margin-right: 24px;
      ${theme.font.medium.fontSize16};
    }
  }

  & > li:last-child {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 16px;

    & > input {
      margin-right: 40px;
    }

    & > label {
      width: 80px;
      height: 22px;
      margin-right: 24px;
      ${theme.font.medium.fontSize16};
    }
  }
`;

const StListInput = styled.input<ListInput>`
  width: 560px;
  height: 56px;
  padding: 16px 16px 18px 16px;
  color: ${({ color }) => color};
  border: 1px solid ${theme.colors.grayLine};
  border-radius: 4px;
  background: ${({ bg }) => bg};
  ${theme.font.regular.fontSize16};
`;

const StBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StBtn = styled.button<Button>`
  width: 140px;
  height: 44px;
  margin: ${({ margin }) => margin};
  border-radius: 4px;
  color: ${theme.colors.bgWhite};
  background: ${({ bg }) => bg};
`;

const UserEdit = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userInfoData = useAppSelector((state) => state.userInfo.data);

  type Info = {
    department?: string;
    phone_number?: string;
    prev_password?: string;
    new_password?: string;
    password_check?: string;
  };

  const [info, setInfo] = useState<Info>({});

  const list = [
    {
      key: "corp_name",
      placeholder: userInfoData.corpName,
      name: "회사명",
      type: "readOnly",
    },
    {
      key: "name",
      placeholder: userInfoData.name,
      name: "이름",
      type: "readOnly",
    },
    {
      key: "id",
      placeholder: userInfoData.id,
      name: "아이디",
      type: "readOnly",
    },
    {
      key: "phone_number",
      placeholder: userInfoData.phoneNumber,
      name: "전화번호",
      type: "change",
    },
    {
      key: "department",
      placeholder: userInfoData.department,
      name: "부서",
      type: "change",
    },
    {
      key: "signUp_dttm",
      placeholder: userInfoData.signUpDttm,
      name: "가입일",
      type: "readOnly",
    },
    {
      key: "prev_password",
      placeholder: "",
      name: "현재 비밀번호 입력",
      type: "change",
    },
    {
      key: "new_password",
      placeholder: "",
      name: "새 비밀번호 입력",
      type: "change",
    },
    {
      key: "password_check",
      placeholder: "",
      name: "새 비밀번호 확인",
      type: "change",
    },
  ];

  const onInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    const keyTyped = name as keyof typeof info;

    if (value === "") {
      delete info[keyTyped];
    } else {
      setInfo({ ...info, [name]: value });
    }
  };

  const onSave = async () => {
    if (info.prev_password) {
      const regPw =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
      if (info.new_password === info.password_check) {
        if (regPw.test(info.new_password as string)) {
          const { statusCode, message } = await editUserInfo(info);

          if (statusCode === 200) {
            // navigate("/user/mypage");
            // dispatch(
            //   alertAction.openModal({ modalName: "alert", text: "수정 완료" })
            // );
            window.location.href = "/user/mypage";
          } else {
            dispatch(
              alertAction.openModal({ modalName: "alert", text: message })
            );
          }
        } else {
          dispatch(
            alertAction.openModal({
              modalName: "alert",
              text: "비밀번호는 영문, 숫자, 특수 문자 3종류 이상 및 8자리 이상이어야 합니다.",
            })
          );
        }
      } else {
        dispatch(
          alertAction.openModal({
            modalName: "alert",
            text: "비밀번호가 일치 하지 않습니다 다시 확인해주세요.",
          })
        );
      }
    } else {
      const { statusCode, message } = await editUserInfo(info);

      if (statusCode === 200) {
        // navigate("/user/mypage");
        // window.location.reload();
        window.location.href = "/user/mypage";
        // dispatch(
        //   alertAction.openModal({ modalName: "alert", text: "수정 완료" })
        // );
      } else {
        dispatch(alertAction.openModal({ modalName: "alert", text: message }));
      }
    }
  };

  useEffect(() => {}, []);

  return (
    <ContainerLayout>
      <StPageTitle>마이페이지 수정</StPageTitle>

      <StUserInfo>
        <h3>사용자 정보 수정</h3>

        <StInfoList>
          {list.map((item, index) => {
            return item.type === "readOnly" ? (
              <li key={index}>
                <label htmlFor="">{item.name}</label>
                <StListInput
                  name={item.key}
                  bg={theme.colors.bgDark}
                  color={theme.colors.grayFont}
                  type="text"
                  placeholder={item.placeholder}
                  readOnly
                />
              </li>
            ) : (
              <>
                {item.key === "prev_password" ||
                item.key === "password_check" ||
                item.key === "new_password" ? (
                  item.key !== "password_check" ? (
                    <li key={index}>
                      <label htmlFor="">{item.name}</label>
                      <StListInput
                        name={item.key}
                        bg={theme.colors.bgWhite}
                        color={theme.colors.black}
                        type="password"
                        placeholder={item.placeholder}
                        onChange={(event) => onInfoChange(event)}
                      />
                    </li>
                  ) : (
                    <li key={index}>
                      <label htmlFor="">{item.name}</label>

                      <StListInput
                        name={item.key}
                        bg={theme.colors.bgWhite}
                        color={theme.colors.black}
                        type="password"
                        placeholder={item.placeholder}
                        onChange={(event) => onInfoChange(event)}
                      />
                    </li>
                  )
                ) : (
                  <li key={index}>
                    <label htmlFor="">{item.name}</label>
                    <StListInput
                      name={item.key}
                      bg={theme.colors.bgWhite}
                      color={theme.colors.black}
                      type="text"
                      placeholder={item.placeholder}
                      onChange={(event) => onInfoChange(event)}
                    />
                  </li>
                )}
              </>
            );
          })}
        </StInfoList>

        <StBtnContainer>
          <StBtn
            margin="0"
            bg={theme.colors.brandColor}
            onClick={() => onSave()}
          >
            저장
          </StBtn>
        </StBtnContainer>
      </StUserInfo>
    </ContainerLayout>
  );
};

export default UserEdit;
