import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import styled from "styled-components";
import {
  StAllCountContainer,
  StControllBottom,
  StControllContainer,
  StControllInput,
  StControllItem,
  StControllSelect,
  StDateContainer,
  StDatePicker,
  StExcelBtn,
  StPageTitle,
  StPaginationContainer,
  StPeriodWord,
  StRightSection,
  StSearchButton,
  StStatusItem,
  StStatusList,
  StTable,
  StWave,
  StWord,
} from "../../components/CommonStyled/ComonStyled";
import EmptyList from "../../components/EmptyList/EmptyList";
import ContainerLayout from "../../components/Layout/ContainerLayout/ContainerLayout";
import { useAppDispatch, useAppSelector } from "../../redux/store/store";
import serviceInfo from "../../services/client/serviceInfo";
import changeDate from "../../services/date/changeDate";
import { theme } from "../../styles/theme";
import { alertAction } from "../../redux/features/modals/alertModal";
import downloadExcel from "../../services/downloadExcel/downloadExcel";

const StServiceContainer = styled.div`
  width: 1560px;
  height: 100%;
  padding: 22px 24px 36px 40px;
  border-radius: 16px;
  background: ${theme.colors.bgWhite};
`;

const StStatusItemText = styled.div`
  color: #acacac;
  word-break: break-all;
  ${theme.font.medium.fontSize14};
`;

type Page = {
  currentPage: number;
  totalCost?: number;
  totalCount: number;
  totalPages: number;
  pageLimit?: number;
};

type Info = {
  page: number;
  pageLimit: number;
  type: "all" | "service" | "admin";
  search?: string;
  sort?:
    | "id"
    | "sp_corp_code"
    | "sp_code"
    | "sp_service_name"
    | "plan_code"
    | "apikey"
    | "sp_admin_name"
    | "sp_admin_phone"
    | "sp_admin_email"
    | "service_code"
    | "sp_quarter_init_dt"
    | "sp_quarter_end_dt"
    | "status";
  sort_order?: "asc" | "desc";
};

type List = {
  id: number;
  spCorpName: string;
  spCode: string;
  spServiceName: string;
  planCode: string;
  apiKey: string;
  spAdminName: string;
  spAdminPhoneNumber: string;
  spAdminEmail: string;
  spQuarterEndDt: string;
  spQuarterInitDt: number;
  status: string;
};

const ServiceInfo = () => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.userInfo.data);
  const [info, setInfo] = useState<Info>({
    page: 1,
    pageLimit: 10,
    type: "all",
  });
  const [page, setPage] = useState<Page>({
    totalCount: 1,
    currentPage: 1,
    totalPages: 1,
  });

  const [typeList, setTypeList] = useState({
    name: [
      {
        name: "No.",
        width: "46",
        height: "20",
        margin: "0 40px 0 16px",
        sort: "id",
      },
      {
        name: "서비스명",
        width: "89",
        height: "20",
        margin: "0 40px 0 0",
        sort: "sp_service_name",
      },
      {
        name: "요금제",
        width: "116",
        height: "20",
        margin: "0 40px 0 0",
        sort: "plan_code",
      },
      {
        name: "API키",
        width: "176",
        height: "20",
        margin: "0 40px 0 0",
        sort: "apikey",
      },
      {
        name: "담당자명",
        width: "96",
        height: "20",
        margin: "0 40px 0 0",
        sort: "sp_admin_name",
      },
      {
        name: "담당자 연락처",
        width: "136",
        height: "20",
        margin: "0 40px 0 0",
        sort: "sp_admin_phone",
      },
      {
        name: "담당자 이메일",
        width: "136",
        height: "20",
        margin: "0 40px 0 0",
        sort: "sp_admin_email",
      },
      {
        name: "사용량초기화일",
        width: "96",
        height: "20",
        margin: "0 40px 0 0",
        sort: "sp_quarter_init_dt",
      },
      {
        name: "종료일",
        width: "116",
        height: "20",
        margin: "0 40px 0 0",
        sort: "sp_quarter_end_dt",
      },
      {
        name: "상태",
        width: "46",
        height: "20",
        margin: "0 0 0 0",
        sort: "status",
      },
    ],
    isOrder: "asc",
  });

  const [list, setList] = useState<List[]>([]);

  const getList = async () => {
    const { statusCode, data, message } = await serviceInfo(info);

    if (statusCode === 200) {
      const { spServiceList, ...others } = await data;
      setList(spServiceList);
      setPage(others);
    } else {
      dispatch(alertAction.openModal({ modalName: "alert", text: message }));
    }
  };

  const onSort = (
    text:
      | "id"
      | "sp_corp_code"
      | "sp_code"
      | "sp_service_name"
      | "plan_code"
      | "apikey"
      | "sp_admin_name"
      | "sp_admin_phone"
      | "sp_admin_email"
      | "service_code"
      | "sp_quarter_init_dt"
      | "sp_quarter_end_dt"
      | "status"
  ) => {
    if (typeList.isOrder === "asc") {
      setTypeList({ ...typeList, isOrder: "desc" });
      setInfo({ ...info, sort: text, sort_order: "desc" });
    } else {
      setTypeList({ ...typeList, isOrder: "asc" });
      setInfo({ ...info, sort: text, sort_order: "asc" });
    }
  };

  const onType = (
    event:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    const name = event.target.name;

    if (name === "pageLimit") {
      setInfo({ ...info, [name]: Number(value) });
      setPage({ ...page, currentPage: 1 });
    } else {
      setInfo({ ...info, [name]: value });
    }
  };

  const handlePageChange = (clickedNumber: number) => {
    setPage({ ...page, currentPage: clickedNumber });
  };

  const DonwLoad = async () => {
    const sendInfo = {
      type: info.type,
      search: info.search ? info.search : "",
    };
    const { statusCode, data, message } = await downloadExcel(sendInfo);
    if (statusCode === 200) {
      const link = await data.downloadLink;
      window.location.href = link;
    } else {
      dispatch(alertAction.openModal({ modalName: "alert", text: message }));
    }
  };

  const resultList = list.map((item) => {
    const {
      id,
      spServiceName,
      apiKey,
      spAdminName,
      spAdminPhoneNumber,
      spAdminEmail,
      spQuarterEndDt,
      status,
      planCode,
      spQuarterInitDt,
    } = item;

    const resultArr = [
      {
        name: id,
        width: "46",
        height: "20",
        margin: "0 40px 0 16px",
        sort: "id",
      },
      {
        name:
          spServiceName.length > 15
            ? `${spServiceName.slice(0, 15)}...`
            : spServiceName,
        width: "89",
        height: "20",
        margin: "0 40px 0 0",
        sort: "sp_service_name",
      },
      {
        name: planCode.length > 15 ? `${planCode.slice(0, 15)}...` : planCode,
        width: "116",
        height: "20",
        margin: "0 40px 0 0",
        sort: "plan_code",
      },
      {
        name: apiKey,
        width: "176",
        height: "20",
        margin: "0 40px 0 0",
        sort: "apikey",
      },
      {
        name: spAdminName,
        width: "96",
        height: "20",
        margin: "0 40px 0 0",
        sort: "sp_admin_name",
      },
      {
        name: spAdminPhoneNumber,
        width: "136",
        height: "20",
        margin: "0 40px 0 0",
        sort: "sp_admin_phone",
      },
      {
        name: spAdminEmail,
        width: "136",
        height: "20",
        margin: "0 40px 0 0",
        sort: "sp_admin_email",
      },
      {
        name: `${spQuarterInitDt}일`,
        width: "96",
        height: "20",
        margin: "0 40px 0 0",
        sort: "sp_quarter_init_dt",
      },
      {
        name: spQuarterEndDt,
        width: "116",
        height: "20",
        margin: "0 40px 0 0",
        sort: "sp_quarter_end_dt",
      },
      {
        name: status,
        width: "46",
        height: "20",
        margin: "0 0 0 0",
        sort: "status",
      },
    ];

    return resultArr;
  });

  useEffect(() => {
    getList();
  }, [info.pageLimit, info.sort_order]);

  return (
    <ContainerLayout>
      <StPageTitle>정보</StPageTitle>

      <StServiceContainer>
        <StControllContainer>
          <StPeriodWord>서비스 정보</StPeriodWord>

          <StRightSection>
            <StControllItem margin="0 16px 0 0">
              <StControllSelect name="type" onChange={(event) => onType(event)}>
                <option value="all">전체</option>
                <option value="service">서비스명</option>
                <option value="admin">담당자명</option>
              </StControllSelect>
            </StControllItem>
            <StControllItem margin="0 24px 0 0">
              <StControllInput
                onChange={(event) => onType(event)}
                name="search"
                width="368"
                height="40"
                padding="9px 16px"
                fontSize="16"
                fontWeight="400"
                type="text"
                placeholder="검색어를 입력해주세요."
              />
            </StControllItem>

            <li>
              <StSearchButton onClick={getList}>검색</StSearchButton>
            </li>
          </StRightSection>
        </StControllContainer>

        <StAllCountContainer>
          <span>전체</span>
          <h4>{page?.totalCount}건</h4>
        </StAllCountContainer>

        <StTable>
          {resultList?.length === 0 ? (
            <EmptyList />
          ) : (
            <>
              <StStatusList>
                {typeList.name.map((item, index) => {
                  return (
                    <StStatusItem
                      key={`${item.name} + ${index} + ${Math.random()}`}
                      width={item.width}
                      margin={item.margin}
                      height={item.height}
                      onClick={() =>
                        onSort(
                          item.sort as
                            | "id"
                            | "sp_corp_code"
                            | "sp_code"
                            | "sp_service_name"
                            | "plan_code"
                            | "apikey"
                            | "sp_admin_name"
                            | "sp_admin_phone"
                            | "sp_admin_email"
                            | "service_code"
                            | "sp_quarter_init_dt"
                            | "sp_quarter_end_dt"
                            | "status"
                        )
                      }
                    >
                      <StStatusItemText>{item.name}</StStatusItemText>
                      {item.sort !== "no" ? (
                        <img src="/imgs/table/status_down.png" alt="down" />
                      ) : (
                        ""
                      )}
                    </StStatusItem>
                  );
                })}
              </StStatusList>

              {resultList?.map((item, index) => {
                const result = item.map((item, index) => {
                  return (
                    <StStatusItem
                      key={`${item.name} + ${index} + ${Math.random()}`}
                      width={item.width}
                      margin={item.margin}
                      height={item.height}
                    >
                      <StStatusItemText>{item.name}</StStatusItemText>
                    </StStatusItem>
                  );
                });
                return (
                  <ul
                    key={index}
                    style={{
                      display: "flex",
                      marginBottom: "40px",
                      height: "60px",
                      alignItems: "center",
                    }}
                  >
                    {result}
                  </ul>
                );
              })}
            </>
          )}
        </StTable>

        <StControllBottom
          style={{ display: resultList?.length === 0 ? "none" : "flex" }}
        >
          <li>
            <StExcelBtn onClick={DonwLoad}>엑셀 저장</StExcelBtn>
          </li>
          <li style={{ flexGrow: 1 }}>
            <StPaginationContainer>
              <Pagination
                activePage={page.currentPage}
                itemsCountPerPage={info.pageLimit}
                totalItemsCount={page.totalPages}
                pageRangeDisplayed={5}
                prevPageText={"‹"}
                nextPageText={"›"}
                onChange={handlePageChange}
              />
            </StPaginationContainer>
          </li>

          <li>
            <StControllSelect
              name="pageLimit"
              defaultValue={10}
              onChange={(event) => onType(event)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </StControllSelect>
            <span style={{ marginLeft: "8px" }}>개씩 보기</span>
          </li>
        </StControllBottom>
      </StServiceContainer>
    </ContainerLayout>
  );
};

export default ServiceInfo;
