import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  StPageTitle,
  StStatusItem,
  StStatusItemText,
  StStatusList,
  StSubTitle,
  StTable,
} from "../../components/CommonStyled/ComonStyled";
import EmptyList from "../../components/EmptyList/EmptyList";
import ContainerLayout from "../../components/Layout/ContainerLayout/ContainerLayout";
import { alertAction } from "../../redux/features/modals/alertModal";
import { useAppDispatch } from "../../redux/store/store";
import clientInfo from "../../services/client/clientInfo";
import { theme } from "../../styles/theme";

const StClientContainer = styled.div`
  width: 1560px;
  height: 938px;
  padding: 22px 24px 36px 40px;
  border-radius: 16px;
  background: ${theme.colors.bgWhite};
`;

type List = {
  spCorpAddress: string;
  spCorpEmail: string;
  spCorpName: string;
  spCorpNumber: string;
  spCorpWeburl: string;
  status: string;
  insertDttm: string;
  updateDttm: string;
};

const ClientInfo = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [info, setInfo] = useState({ page: 1 });
  const [typeList, setTypeList] = useState({
    name: [
      { name: "회사명", width: "100", height: "20", margin: "0 32px 0 16px" },
      {
        name: "회사 대표 전화번호",
        width: "160",
        height: "20",
        margin: "0 32px 0 0",
      },
      {
        name: "회사 대표 이메일",
        width: "160",
        height: "20",
        margin: "0 32px 0 0",
      },
      {
        name: "회사 홈페이지",
        width: "160",
        height: "20",
        margin: "0 32px 0 0",
      },
      { name: "회사 주소", width: "240", height: "20", margin: "0 32px 0 0" },
      { name: "상태", width: "80", height: "20", margin: "0 32px 0 0" },
      { name: "등록일시", width: "160", height: "20", margin: "0 32px 0 0" },
      { name: "수정일시", width: "160", height: "20", margin: "0 60px 0 0" },
    ],
    isOrder: "asc",
  });
  const [list, setList] = useState<List[]>();
  const [page, setPage] = useState();

  const getList = async () => {
    const { statusCode, data, message } = await clientInfo();
    if (statusCode === 200) {
      setList(data);
    } else {
      dispatch(alertAction.openModal({ modalName: "alert", text: message }));
    }
  };

  const resultList = list?.map((item) => {
    const {
      spCorpName,
      spCorpNumber,
      spCorpEmail,
      spCorpWeburl,
      spCorpAddress,
      status,
      insertDttm,
      updateDttm,
    } = item;
    const resultArr = [
      {
        name: spCorpName,
        width: "100",
        height: "20",
        margin: "0 32px 0 16px",
        type: "spCorpName",
      },
      {
        name: spCorpNumber,
        width: "160",
        height: "20",
        margin: "0 32px 0 0",
      },
      {
        name: spCorpEmail,
        width: "160",
        height: "20",
        margin: "0 32px 0 0",
      },
      {
        name: spCorpWeburl,
        width: "160",
        height: "20",
        margin: "0 32px 0 0",
      },
      { name: spCorpAddress, width: "240", height: "20", margin: "0 32px 0 0" },
      { name: status, width: "80", height: "20", margin: "0 32px 0 0" },
      { name: insertDttm, width: "160", height: "20", margin: "0 32px 0 0" },
      { name: updateDttm, width: "160", height: "20", margin: "0 60px 0 0" },
    ];

    return resultArr;
  });

  useEffect(() => {
    getList();
  }, []);

  return (
    <ContainerLayout>
      <StPageTitle>정보</StPageTitle>

      <StClientContainer>
        <StSubTitle>고객사 정보</StSubTitle>

        <StTable>
          {resultList?.length === 0 ? (
            <EmptyList />
          ) : (
            <>
              <StStatusList>
                {typeList.name.map((item, index) => {
                  return (
                    <StStatusItem
                      key={index}
                      width={item.width}
                      margin={item.margin}
                      height={item.height}
                    >
                      <StStatusItemText>{item.name}</StStatusItemText>
                    </StStatusItem>
                  );
                })}
              </StStatusList>

              {resultList?.map((item, index) => {
                const result = item.map((item) => {
                  return (
                    <StStatusItem
                      key={`${item.name} + ${index} + ${Math.random()}`}
                      width={item.width}
                      margin={item.margin}
                      height={item.height}
                      onClick={
                        item.type
                          ? (event) => navigate("/info/client/edit")
                          : () => {
                              return;
                            }
                      }
                    >
                      <StStatusItemText
                        style={{
                          color:
                            item.type === "spCorpName"
                              ? `${theme.colors.brandColor}`
                              : "",
                          borderBottom:
                            item.type === "spCorpName"
                              ? `1px solid ${theme.colors.brandColor}`
                              : "",
                          cursor:
                            item.type === "spCorpName" ? "pointer" : "auto",
                        }}
                      >
                        {item.name}
                      </StStatusItemText>
                    </StStatusItem>
                  );
                });
                return (
                  <ul
                    key={index}
                    style={{
                      display: "flex",
                      height: "60px",
                      alignItems: "center",
                    }}
                  >
                    {result}
                  </ul>
                );
              })}
            </>
          )}
        </StTable>
      </StClientContainer>
    </ContainerLayout>
  );
};

export default ClientInfo;
