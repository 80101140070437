import React from "react";
import styled from "styled-components";
import { theme } from "../../../styles/theme";

const StContainer = styled.div`
  flex-grow: 1;
  margin-bottom: 64px;
  padding: 40px;
  background: ${theme.colors.bgDark};
`;

interface Props {
  children: React.ReactNode;
}

const ContainerLayout: React.FC<Props> = ({ children }) => {
  return <StContainer>{children}</StContainer>;
};

export default ContainerLayout;
