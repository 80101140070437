import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Modal {
  actionModal: boolean;
  actionModalName: string;
  text?: string;
  value?: string | number | object;
  store?: any;
  path?: string;
}

const initialState: Modal = {
  actionModal: false,
  actionModalName: "",
  text: "",
  value: "",
  store: [],
  path: "",
};

const actionModal = createSlice({
  name: "action",
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{
        modalName: string;
        text?: string;
        value?: any;
        store?: any;
        path?: string;
      }>
    ) => {
      state.actionModal = true;
      state.actionModalName = action.payload.modalName;
      state.text = action.payload.text || "";
      state.value = action.payload.value;
      state.store = action.payload.store;
      state.path = action.payload.path;
    },
    closeModal: (state) => {
      state.actionModal = false;
      state.actionModalName = "";
      state.text = "";
    },
  },
});

export const modalAction = actionModal.actions;

export default actionModal;
