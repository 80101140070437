import axios, { AxiosPromise } from "axios";
import refresh from "../auth/refresh/refresh";

type D = {
  statusCode: 200;
  data: [];
  message: string;
};

interface ServiceList {
  (): AxiosPromise<D>;
}

const serviceList: ServiceList = async () => {
  const idToken = window.sessionStorage.getItem("idToken");

  try {
    const response = await axios({
      url: "/service/member/subs-list",
      method: "get",
      headers: {
        accept: `application/json`,
        Authorization: `Bearer ${idToken}`,
      },
    });
    return response;
  } catch (error: any) {
    if (error.response.data.statusCode === 401) {
      await refresh();
      return serviceList();
    } else {
      return error.response;
    }
  }
};

export default serviceList;
