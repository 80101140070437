import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  StPageTitle,
  StSubTitle,
} from "../../components/CommonStyled/ComonStyled";
import ContainerLayout from "../../components/Layout/ContainerLayout/ContainerLayout";
import { useAppDispatch, useAppSelector } from "../../redux/store/store";
import { theme } from "../../styles/theme";
import userInfo from "../../services/user/userInfo";
import contactPost from "../../services/contact/contactPost";
import { alertAction } from "../../redux/features/modals/alertModal";
import { useLocation, useNavigate } from "react-router-dom";
import selectContactPost from "../../services/contact/selectContactPost";

const StContactEditContainer = styled.div`
  width: 1560px;
  min-height: 772px;
  padding: 40px;
  border-radius: 16px;
  background: ${theme.colors.bgWhite};
`;

const StUserInfoList = styled.ul`
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
`;

interface UserItem {
  width: string;
}

const StUserInfoItem = styled.li<UserItem>`
  width: ${({ width }) => width};
  margin-bottom: 16px;
  display: flex;

  & > label {
    width: 80px;
    height: 22px;
    margin-right: 24px;
    ${theme.font.medium.fontSize16};
  }

  & > select {
    width: 560px;
    height: 56px;
    padding: 17px 16px;
    background: ${theme.colors.bgDark};
    border: 1px solid ${theme.colors.grayLine};
    border-radius: 4px;
  }

  & > textarea {
    width: 88%;
    height: 272px;
    padding: 17px 16px;
    background: ${theme.colors.bgDark};
    border: 1px solid ${theme.colors.grayLine};
    border-radius: 4px;
    resize: none;
  }
`;

const StFullInput = styled.input`
  width: 88%;
  height: 56px;
  padding: 17px 16px;
  background: ${theme.colors.bgDark};
  border: 1px solid ${theme.colors.grayLine};
  border-radius: 4px;
`;

const StHalfInput = styled.input`
  width: 560px;
  height: 56px;
  padding: 17px 16px;
  background: ${theme.colors.bgDark};
  border: 1px solid ${theme.colors.bgDark};
  border-radius: 4px;
`;

const StBtnContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const StBtn = styled.button`
  width: 200px;
  height: 44px;
  padding: 10px 20px;
  border-radius: 5px;
  color: ${theme.colors.bgWhite};
  background: ${theme.colors.brandColor};
  ${theme.font.medium.fontSize18};
`;

type ContactUserInfo = {
  text: string;
  value: string | number[];
  type: "input" | "select" | "textarea";
  width: string;
};

const SelectPost = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const postId = useLocation().state.postId;

  const userInfo = useAppSelector((state) => state.userInfo.data);
  const [contactUserInfo, SetContactUserInfo] = useState<ContactUserInfo[]>([]);
  const [selectService, setSelectService] = useState("");

  const onTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const findIndex = contactUserInfo.findIndex(
      (item) => item.text === "문의 제목"
    );

    const copy = [...contactUserInfo];
    copy[findIndex].value = value;
    SetContactUserInfo(copy);
  };

  const onContent = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    const findIndex = contactUserInfo.findIndex(
      (item) => item.text === "문의 내용"
    );

    const copy = [...contactUserInfo];
    copy[findIndex].value = value;
    SetContactUserInfo(copy);
  };

  const onService = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.currentTarget.value;
    setSelectService(value);
  };

  const onSave = async () => {
    if (
      selectService === "" ||
      contactUserInfo[6].value === "" ||
      contactUserInfo[7].value === ""
    ) {
      dispatch(
        alertAction.openModal({
          modalName: "alert",
          text: "제목 또는 내용을 확인해주세요.",
        })
      );
    } else {
      const info = {
        title: contactUserInfo[6].value,
        content: contactUserInfo[7].value,
        spServiceName: selectService,
      };
      const { statusCode, data, message } = await contactPost(info);

      if (statusCode === 200) {
        dispatch(alertAction.openModal({ modalName: "alert", text: message }));
      } else {
        dispatch(alertAction.openModal({ modalName: "alert", text: message }));
      }
    }
  };

  const getPostData = async () => {
    const { statusCode, data, message } = await selectContactPost(postId);

    if (statusCode === 200) {
      const {
        authorDepartment,
        authorId,
        authorName,
        authorPhone,
        content,
        spCorpName,
        spServiceName,
        title,
      } = await data;

      SetContactUserInfo([
        { text: "회사명", value: spCorpName, type: "input", width: "50%" },
        {
          text: "서비스명",
          value: spServiceName,
          type: "select",
          width: "50%",
        },
        { text: "이름", value: authorName, type: "input", width: "50%" },
        { text: "아이디", value: authorId, type: "input", width: "50%" },
        {
          text: "전화번호",
          value: authorPhone,
          type: "input",
          width: "50%",
        },
        { text: "부서", value: authorDepartment, type: "input", width: "50%" },
        { text: "문의 제목", value: title, type: "input", width: "100%" },
        { text: "문의 내용", value: content, type: "textarea", width: "100%" },
      ]);
    } else {
      dispatch(alertAction.openModal({ modalName: "alert", text: message }));
    }
  };

  useEffect(() => {
    setSelectService(String(userInfo.service[0]));
    getPostData();
  }, [userInfo]);

  return (
    <ContainerLayout>
      <StPageTitle>문의하기</StPageTitle>

      <StContactEditContainer>
        <StSubTitle>문의하기</StSubTitle>
        <StUserInfoList>
          {contactUserInfo.map((item, index) => {
            return (
              <StUserInfoItem key={index} width={item.width}>
                <label>{item.text}</label>

                {item.type === "select" && (
                  <StHalfInput
                    type="service"
                    placeholder={String(userInfo?.service[0])}
                    readOnly
                  ></StHalfInput>
                )}

                {item.type === "input" &&
                  (item.text === "문의 제목" ? (
                    <StFullInput
                      type="text"
                      placeholder={String(contactUserInfo[6].value)}
                      readOnly
                    />
                  ) : (
                    <StHalfInput
                      type="text"
                      placeholder={item.value ? String(item.value) : ""}
                      readOnly
                    />
                  ))}

                {item.type === "textarea" && (
                  <textarea
                    name="content"
                    placeholder={String(contactUserInfo[7].value)}
                    readOnly
                  />
                )}
              </StUserInfoItem>
            );
          })}
        </StUserInfoList>
        <StBtnContainer>
          <StBtn
            style={{
              marginRight: "16px",
              color: "white",
              background: `${theme.colors.black}`,
            }}
            onClick={() =>
              navigate(`/contact/post/${postId}/edit`, { state: { postId } })
            }
          >
            수정
          </StBtn>
          <StBtn onClick={onSave}>확인</StBtn>
        </StBtnContainer>
      </StContactEditContainer>
    </ContainerLayout>
  );
};

export default SelectPost;
