import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyles = createGlobalStyle`
 ${reset}
 
 *{
   box-sizing:border-box;
 }
 
 html{
   font-size: 10px;
 }
 
 body{
  background-color: #ffffff;
  font-family: 'Pretendard';
  color: #222222;
 }
 
 a{
   text-decoration:none;
   color:inherit;
   cursor: pointer;
 }

 ul {
   padding-left: 0px;
 }

 li {
   list-style : none;
 }
 input {
   margin : 0;
 }

 button {
   margin : 0;
   padding : 0;
   border : none;
   border-radius:0;
   box-shadow:none; 
   cursor : pointer;
 }

  input,textarea{
    font-family: 'Pretendard';
    color: #222222;
  }
`;

export default GlobalStyles;
