import { useState } from "react";
import styled from "styled-components";
import login from "../../services/auth/login/login";
import { theme } from "../../styles/theme";

const StLoginContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;

const StLeftSection = styled.section`
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > h2 {
      font-weight: 700;
      font-size: 54px;
      line-height: 68px;
    }
  }

  & > p {
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
  }

  & > img {
    width: 280px;
    height: 280px;
  }
`;

const StRightSection = styled.section`
  width: calc(100% - 800px);
  min-width: 1120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.bgDark};

  & > form {
    width: 500px;
    height: 380px;

    & > p {
      margin-bottom: 40px;
      font-size: 40px;
      font-weight: 700;
      line-height: 48px;
    }
  }
`;

const StInputBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const StLabel = styled.label`
  margin-bottom: 8px;
  ${theme.font.medium.fontSize16};
`;

const StInput = styled.input`
  width: 500px;
  height: 56px;
  padding: 16px;
  background: ${theme.colors.bgWhite};
  border: 1px solid ${theme.colors.grayLine};
  border-radius: 12px;
  ${theme.font.regular.fontSize18};
`;

const StButton = styled.button`
  width: 500px;
  height: 64px;
  background: ${theme.colors.grayLine};
  border-radius: 12px;
  color: ${theme.colors.bgWhite};
  ${theme.font.bold.fontSize24};

  &:hover {
    background: ${theme.colors.brandColor};
  }
`;

const StImgContainer = styled.div`
  margin-top: 236px;
  display: flex;
  justify-content: center;
`;

const StTitleImg = styled.img`
  width: 208px;
  height: 33.15px;
`;

const StErrorText = styled.div`
  margin-top: 4px;
  margin-left: 4px;
  color: ${theme.colors.error};
  ${theme.font.medium.fontSize14};
`;

const Login = () => {
  const [id, setId] = useState("");
  const [pw, setPw] = useState("");
  const [errorIdInfo, setErrorIdInfo] = useState({ text: "", isShow: false });
  const [errorPwInfo, setErrorPwInfo] = useState({ text: "", isShow: false });

  const onId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setId(value);
  };

  const onPw = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPw(value);
  };

  const onLogin = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setErrorIdInfo({ text: "", isShow: false });
    setErrorPwInfo({ text: "", isShow: false });
    const { statusCode, data, message } = await login(id, pw);

    if (statusCode === 200) {
      window.sessionStorage.setItem("accessToken", data.accessToken);
      window.sessionStorage.setItem("refreshToken", data.refreshToken);
      window.sessionStorage.setItem("idToken", data.idToken);

      window.location.href = "/";
    } else if (statusCode === 210) {
      const session = await data.session;
      window.sessionStorage.setItem("sessionToken", await session);

      window.location.href = "/";
    } else {
      if (statusCode === 410)
        return setErrorIdInfo({ text: message, isShow: true });
      if (statusCode === 411)
        return setErrorPwInfo({ text: message, isShow: true });
      if (statusCode === 412)
        return setErrorPwInfo({ text: message, isShow: true });
      if (statusCode === 413)
        return setErrorIdInfo({ text: message, isShow: true });
      if (statusCode === 414)
        return setErrorIdInfo({ text: message, isShow: true });
      if (statusCode === 415)
        return setErrorIdInfo({ text: message, isShow: true });
      else {
        setErrorIdInfo({ text: message, isShow: true });
      }
    }
  };

  return (
    <StLoginContainer>
      <StLeftSection>
        <div>
          <h2>All You Need is</h2>
          <h2>TUNiB's APIs</h2>
        </div>
        <p>NLP Cloud API Services</p>
        <img src="/imgs/login/login_left.png" alt="login_left" />
      </StLeftSection>

      <StRightSection>
        <form>
          <p>TUNiBridge Connect</p>

          <StInputBox style={{ marginBottom: "16px" }}>
            <StLabel htmlFor="id">ID</StLabel>
            <StInput
              type="text"
              id="id"
              onChange={(event) => onId(event)}
              value={id}
              placeholder={`이메일 주소를 입력해주세요.`}
            />
            {errorIdInfo.isShow && (
              <StErrorText>{errorIdInfo.text}</StErrorText>
            )}
          </StInputBox>

          <StInputBox style={{ marginBottom: "40px" }}>
            <StLabel htmlFor="pw">Password</StLabel>
            <StInput
              type="password"
              id="pw"
              value={pw}
              onChange={(event) => onPw(event)}
              placeholder={`비밀번호를 입력해주세요.`}
            />
            {errorPwInfo.isShow && (
              <StErrorText>{errorPwInfo.text}</StErrorText>
            )}
          </StInputBox>

          <StButton onClick={(event) => onLogin(event)}>Login</StButton>

          <StImgContainer>
            <StTitleImg src="/imgs/login/title_img.png" alt="title_img" />
          </StImgContainer>
        </form>
      </StRightSection>
    </StLoginContainer>
  );
};

export default Login;
