import React from "react";
import styled from "styled-components";
import { theme } from "../../styles/theme";

const StContainer = styled.li`
  width: 100%;
  height: 680px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StEmptyWord = styled.h2`
  ${theme.font.medium.fontSize20};
`;

const EmptyList = () => {
  return (
    <StContainer>
      <StEmptyWord>일치하는 검색 결과가 없습니다.</StEmptyWord>
    </StContainer>
  );
};

export default EmptyList;
