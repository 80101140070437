import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  colors: {
    brandColor: "#7FC41C",
    secondaryBtn: "#222222",
    grayFont: "#666666",
    grayIcon: "#999999",
    grayLine: "#DDDDDD",
    bgDark: "#f3f3f3",
    bgLight: "#f8f8f8",
    bgWhite: "#FFFFFF",
    black: "#111111",
    error: "#F56A57",
  },
  font: {
    regular: {
      fontSize26: {
        "font-weight": "400",
        "line-height": "32px",
        "font-size": "26px",
        "letter-spacing": "-0.02rem",
      },
      fontSize24: {
        "font-weight": "400",
        "line-height": "30px",
        "font-size": "24px",
        "letter-spacing": "-0.02rem",
      },
      fontSize18: {
        "font-weight": "400",
        "line-height": "24px",
        "font-size": "18px",
        "letter-spacing": "-0.02rem",
      },
      fontSize16: {
        "font-weight": "400",
        "line-height": "22px",
        "font-size": "16px",
        "letter-spacing": "-0.02rem",
      },
      fontSize14: {
        "font-weight": "400",
        "line-height": "20px",
        "font-size": "14px",
        "letter-spacing": "-0.02rem",
      },
    },
    medium: {
      fontSize32: {
        "font-weight": "500",
        "line-height": "38px",
        "font-size": "32px",
        "letter-spacing": "-0.02rem",
      },
      fontSize26: {
        "font-weight": "500",
        "line-height": "32px",
        "font-size": "26px",
        "letter-spacing": "-0.02rem",
      },
      fontSize24: {
        "font-weight": "500",
        "line-height": "30px",
        "font-size": "24px",
        "letter-spacing": "-0.02rem",
      },
      fontSize20: {
        "font-weight": "500",
        "line-height": "26px",
        "font-size": "20px",
        "letter-spacing": "-0.02rem",
      },
      fontSize18: {
        "font-weight": "500",
        "line-height": "24px",
        "font-size": "18px",
        "letter-spacing": "-0.02rem",
      },
      fontSize16: {
        "font-weight": "500",
        "line-height": "22px",
        "font-size": "16px",
        "letter-spacing": "-0.02rem",
      },
      fontSize14: {
        "font-weight": "500",
        "line-height": "20px",
        "font-size": "14px",
        "letter-spacing": "-0.02rem",
      },
    },
    bold: {
      fontSize26: {
        "font-weight": "700",
        "line-height": "32px",
        "font-size": "26px",
        "letter-spacing": "-0.02rem",
      },
      fontSize24: {
        "font-weight": "700",
        "line-height": "30px",
        "font-size": "24px",
        "letter-spacing": "-0.02rem",
      },
      fontSize18: {
        "font-weight": "700",
        "line-height": "24px",
        "font-size": "18px",
        "letter-spacing": "-0.02rem",
      },
      fontSize16: {
        "font-weight": "700",
        "line-height": "22px",
        "font-size": "16px",
        "letter-spacing": "-0.02rem",
      },
      fontSize14: {
        "font-weight": "700",
        "line-height": "20px",
        "font-size": "14px",
        "letter-spacing": "-0.02rem",
      },
    },
  },
};

export { theme };
