import axios, { AxiosPromise } from "axios";
import refresh from "../auth/refresh/refresh";

type Info = {
  page: number;
  type: "all" | "service" | "admin";
  pageLimit?: 10 | 20 | 50;
  search?: string;
  sort?:
    | "id"
    | "sp_corp_code"
    | "sp_code"
    | "sp_service_name"
    | "plan_code"
    | "apikey"
    | "sp_admin_name"
    | "sp_admin_phone"
    | "sp_admin_email"
    | "service_code"
    | "sp_quarter_init_dt"
    | "sp_quarter_end_dt"
    | "status";
  sort_order?: "asc" | "desc";
};

const serviceInfo: any = async (info: Info) => {
  const accessToken = window.sessionStorage.getItem("accessToken");
  const idToken = window.sessionStorage.getItem("idToken");

  try {
    const response = await axios({
      url: "/service/member/info",
      method: "get",
      headers: {
        Accept: `application/json`,
        Authorization: `Bearer ${idToken}`,
        "Access-Token": accessToken,
      },
      params: {
        ...info,
      },
    });

    return response.data;
  } catch (error: any) {
    const statusCode = error.response.data.statusCode;
    if (statusCode === 401) {
      await refresh();
      return serviceInfo(info);
    } else {
      return error.response.data;
    }
  }
};

export default serviceInfo;
