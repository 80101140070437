import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Modal {
  modal: boolean;
  modalName: string;
  text?: string;
  value?: string | number | object;
  store?: any;
}

const initialState: Modal = {
  modal: false,
  modalName: "",
  text: "",
  value: "",
  store: [],
};

const alert = createSlice({
  name: "alert",
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{
        modalName: string;
        text?: string;
        value?: any;
        store?: any;
      }>
    ) => {
      state.modal = true;
      state.modalName = action.payload.modalName;
      state.text = action.payload.text || "";
      state.value = action.payload.value;
      state.store = action.payload.store;
    },
    closeModal: (state) => {
      state.modal = false;
      state.modalName = "";
      state.text = "";
    },
  },
});

export const alertAction = alert.actions;

export default alert;
