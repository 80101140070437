import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import {
  StBtnContainer,
  StControllContainer,
  StControllItem,
  StControllSelect,
  StDateContainer,
  StDatePicker,
  StExcelBtn,
  StGraphBtn,
  StGraphBtnContainer,
  StGraphWord,
  StListContainer,
  StPeriodWord,
  StRightSection,
  StSearchButton,
  StTableItemContainer,
  StTalbleItem,
  StWave,
} from "../CommonStyled/ComonStyled";
import { ResponsiveLine } from "@nivo/line";
import changeDate from "../../services/date/changeDate";
import { useAppDispatch } from "../../redux/store/store";
import { alertAction } from "../../redux/features/modals/alertModal";
import service from "../../services/graph/service";
import { changeNum } from "../../services/common/changeCost";
import excelDownload from "../../services/graph/excelDownload";
import monthCostTotal from "../../services/graph/monthCostTotal";
import { ResponsiveBar } from "@nivo/bar";
import { data1 } from "../../services/test/data";
import monthCostTotalExcel from "../../services/downloadExcel/monthCostTotalExcel";

type GraphDataDay = {
  stdDt: string;
  value: number;
};

type GraphDataWeek = {
  week: string;
  stats: {
    stdDt: string;
    value: number;
  }[];
};

const StGraphContainer = styled.div`
  width: 1560px;
  min-height: 530px;
  margin-top: 40px;
  padding: 22px 24px 24px 40px;
  border-radius: 16px;
  background: ${theme.colors.bgWhite};
`;

const StGraphTitle = styled.h2`
  margin-bottom: 16px;
  color: ${theme.colors.brandColor};
  ${theme.font.medium.fontSize20};
`;

const StGraph = styled.div`
  width: 1496px;
  height: 300px;
  border-radius: 0px;
  // overflow: scroll;
`;

const StTable = styled.div`
  margin-top: 30px;
  overflow: scroll;
`;

const MonthCostTotalGraph = () => {
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<any>(
    new Date(new Date(new Date().setDate(new Date().getDate() - 30)))
  );
  const [endDate, setEndDate] = useState<any>(new Date());
  const [info, setInfo] = useState({
    start: startDate,
    end: endDate,
    period: "day",
  });

  const DATA: any = [];
  const [graphData, setGraphData] = useState([]);
  const [spread, setSpread] = useState(false);
  const [dataList, setDataList] = useState<string[]>([]);

  const onChangeDate = (type: string, date: any) => {
    if (type === "start") {
      setStartDate(date);
      setInfo({ ...info, [type]: changeDate(date) });
    } else {
      setEndDate(date);
      setInfo({ ...info, [type]: changeDate(date) });
    }
  };

  const onType = (
    event:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    const name = event.target.name;

    setInfo({ ...info, [name]: value });
  };

  const getService = async () => {
    const { statusCode, data, message } = await monthCostTotal(info);
    if (statusCode === 200) {
      setGraphData(data.stats);
      setDataList(data.stdDtList);
    } else {
      dispatch(alertAction.openModal({ modalName: "alert", text: message }));
    }
  };

  const gdata = graphData.map((item: any) => {
    if (info.period === "day") {
      const x = `${item.stdDt.slice(4, 6)}/${item.stdDt.slice(6, 8)}`;
      const y = item.value;
      return { x, y };
    } else {
      const x = item.stdDt;
      const y = item.value;
      return { x, y };
    }
  });

  const chart = graphData.map((item: any) => {
    if (info.period === "day") {
      const x = `${item.stdDt.slice(4, 6)}/${item.stdDt.slice(6, 8)}`;
      const y = item.value;
      return { x, y };
    } else {
      const x = item.stdDt;
      const y = item.value;
      return { x, y };
    }
  });

  const DonwLoad = async () => {
    const { statusCode, data, message } = await monthCostTotalExcel(info);
    if (statusCode === 200) {
      const link = await data.downloadLink;
      window.location.href = link;
    } else {
      dispatch(alertAction.openModal({ modalName: "alert", text: message }));
    }
  };

  useEffect(() => {
    getService();
  }, [info.period]);

  return (
    <StGraphContainer>
      <StControllContainer>
        <StPeriodWord>조회기간</StPeriodWord>

        <StDateContainer>
          <StDatePicker
            dateFormat="yyyy-MM-dd"
            selected={startDate}
            onChange={(date) => onChangeDate("start", date)}
            startDate={startDate}
            endDate={endDate}
          />
          <StWave src="/imgs/calendar/~.png" alt="~" />
          <StDatePicker
            dateFormat="yyyy-MM-dd"
            selected={endDate}
            onChange={(date) => onChangeDate("end", date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          />
        </StDateContainer>

        <StControllItem width={"100px"} margin="0 24px 0 0">
          <StGraphWord>봉단위</StGraphWord>
        </StControllItem>

        <StControllItem margin="0 16px 0 0">
          <StControllSelect
            style={{ width: "160px", height: "40px" }}
            name="period"
            onChange={(event) => onType(event)}
          >
            <option value={"day"}>일간</option>
            <option value={"week"}>주간</option>
            <option value={"month"}>월간</option>
          </StControllSelect>
        </StControllItem>

        <StRightSection>
          <StControllItem margin="0 16px 0 0">
            <StSearchButton onClick={getService}>조회</StSearchButton>
          </StControllItem>

          <li>
            <StSearchButton
              style={{
                background: `${theme.colors.black}`,
              }}
              onClick={() => console.log("상세보기")}
            >
              상세보기
            </StSearchButton>
          </li>
        </StRightSection>
      </StControllContainer>

      <StGraphTitle>총 사용량</StGraphTitle>

      <StGraph>
        <ResponsiveBar
          data={gdata}
          tooltip={({ data }) => (
            <div
              style={{
                background: "white",
                padding: "9px 12px",
                border: "1px solid #ccc",
                borderRadius: "6px",
                fontWeight: "bold",
              }}
            >
              <div>
                <strong>날짜 :</strong> {data.x}
              </div>
              <div>
                <strong>사용량 :</strong> {data.y}
              </div>
            </div>
          )}
          keys={["y"]}
          indexBy="x"
          margin={{ top: 50, right: 10, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={{ scheme: "nivo" }}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "#38bcb2",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "#eed312",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          fill={[
            {
              match: {
                id: "fries",
              },
              id: "dots",
            },
            {
              match: {
                id: "sandwich",
              },
              id: "lines",
            },
          ]}
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: "middle",
            legendOffset: -40,
          }}
          enableLabel={false}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          role="application"
        />
      </StGraph>
      <StGraphBtnContainer>
        <StExcelBtn onClick={DonwLoad}>엑셀 저장</StExcelBtn>

        <StGraphBtn
          onClick={() => {
            setSpread(!spread);
          }}
        >
          {!spread ? <div>펼쳐보기</div> : <div>닫기</div>}
          <img src="/imgs/graph/icon_listmenu.png" alt="icon" />
        </StGraphBtn>
      </StGraphBtnContainer>

      {spread && (
        <>
          <StTable>
            <StListContainer>
              {dataList.map((item, index) => (
                <StTalbleItem key={index}>{item}</StTalbleItem>
              ))}
            </StListContainer>
            <div style={{ display: "flex" }}>
              {chart.map((item, index) => {
                return (
                  <StTableItemContainer key={index}>
                    <StTalbleItem key={index}>{changeNum(item.y)}</StTalbleItem>
                  </StTableItemContainer>
                );
              })}
            </div>
          </StTable>
        </>
      )}
    </StGraphContainer>
  );
};

export default MonthCostTotalGraph;
