import axios, { AxiosPromise } from "axios";
import refresh from "../auth/refresh/refresh";

interface Info {
  id: string;
  new_password: string;
}

const resetPw: any = async (info: Info) => {
  const idToken = window.sessionStorage.getItem("idToken");
  const accessToken = window.sessionStorage.getItem("accessToken");
  const session = window.sessionStorage.getItem("sessionToken");

  try {
    const response = await axios({
      url: "/account/member/password_reset",
      method: "put",
      headers: {
        accept: `application/json`,
        Authorization: `Bearer ${idToken}`,
        "Access-Token": accessToken,
      },
      params: {
        ...info,
        session,
      },
    });

    return response.data;
  } catch (error: any) {
    const statusCode = error.response.data.statusCode;
    if (statusCode === 418) {
      window.sessionStorage.clear();
      return error.response.data;
    } else {
      return error.response.data;
    }
  }
};

export default resetPw;
