import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  zIndex: number;
}

interface PortaConatainer {
  zIndex: number;
}

const StLayoutContainer = styled.section<PortaConatainer>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: ${(props) => props.zIndex};
`;

const PortalLayout: React.FC<Props> = ({ children, zIndex }) => {
  return <StLayoutContainer zIndex={zIndex}>{children}</StLayoutContainer>;
};

export default PortalLayout;
