import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ContainerLayout from "../../../components/Layout/ContainerLayout/ContainerLayout";
import { UserData } from "../../../redux/features/type/type";
import { useAppSelector } from "../../../redux/store/store";
import { theme } from "../../../styles/theme";

const StPageTitle = styled.h2`
  margin-bottom: 24px;
  ${theme.font.bold.fontSize26};
`;

const StUserInfo = styled.div`
  width: 1560px;
  padding: 32px 40px 40px 32px;
  border-radius: 12px;
  background: ${theme.colors.bgWhite};

  & > h3 {
    margin-bottom: 16px;
    ${theme.font.bold.fontSize18};
  }
`;

const StInfoList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;

  & > li {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    & > label {
      width: 80px;
      height: 22px;
      margin-right: 24px;
      ${theme.font.medium.fontSize16};
    }

    & > input {
      width: 560px;
      height: 56px;
      padding: 16px 16px 18px 16px;
      color: ${theme.colors.grayFont};
      border: 1px solid ${theme.colors.grayLine};
      border-radius: 4px;
      background: ${theme.colors.bgDark};
      ${theme.font.regular.fontSize16};
    }
  }
`;

const StBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface Button {
  bg: string;
  margin?: string;
}

const StBtn = styled.button<Button>`
  width: 140px;
  height: 44px;
  margin: ${({ margin }) => margin};
  border-radius: 4px;
  color: ${theme.colors.bgWhite};
  background: ${({ bg }) => bg};
`;

const MyPage = () => {
  const naviagate = useNavigate();
  const userInfoData = useAppSelector((state) => state.userInfo.data);
  const list = [
    { key: userInfoData.corpName, name: "회사명" },
    { key: userInfoData.name, name: "이름" },
    { key: userInfoData.id, name: "아이디" },
    { key: userInfoData.phoneNumber, name: "전화번호" },
    { key: userInfoData.department, name: "부서" },
    { key: userInfoData.signUpDttm, name: "가입일" },
  ];

  const goPath = (path: string) => {
    naviagate(path);
  };

  return (
    <ContainerLayout>
      <StPageTitle>마이페이지</StPageTitle>

      <StUserInfo>
        <h3>사용자 정보</h3>

        <StInfoList>
          {list.map((item, index) => {
            return (
              <li key={index}>
                <label htmlFor="">{item.name}</label>
                <input type="text" placeholder={item.key} readOnly />
              </li>
            );
          })}
        </StInfoList>

        <StBtnContainer>
          <StBtn
            margin="0 16px 0 0"
            bg={theme.colors.secondaryBtn}
            onClick={() => goPath("/user/edit")}
          >
            수정
          </StBtn>
          <StBtn
            margin="0"
            bg={theme.colors.brandColor}
            onClick={() => goPath("/")}
          >
            확인
          </StBtn>
        </StBtnContainer>
      </StUserInfo>
    </ContainerLayout>
  );
};

export default MyPage;
