import axios, { AxiosPromise } from "axios";
import refresh from "../auth/refresh/refresh";

interface Info {
  department?: string;
  phone_number?: string;
  prev_password?: string;
  new_password?: string;
}

interface EditUserInfo {
  (info: Info): AxiosPromise;
}

const editUserInfo: any = async (info: Info) => {
  const accessToken = window.sessionStorage.getItem("accessToken");
  const idToken = window.sessionStorage.getItem("idToken");

  try {
    const response = await axios({
      url: "/account/member",
      method: "put",
      headers: {
        Accept: `application/json`,
        Authorization: `Bearer ${idToken}`,
        "Access-Token": accessToken,
      },
      params: {
        ...info,
      },
    });

    return response.data;
  } catch (error: any) {
    const statusCode = error.response.data.statusCode;
    if (statusCode === 401) {
      await refresh();
      return editUserInfo(info);
    } else {
      return error.response.data;
    }
  }
};

export default editUserInfo;
