import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import userInfo from "../../services/user/userInfo";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import AppLayout from "../Layout/AppLayout/AppLayout";
import Navigation from "../Navigation/Navigation";
import { userInfoActions } from "../../redux/features/products/user/userInfo";
import { useAppSelector } from "../../redux/store/store";
import { theme } from "../../styles/theme";

const StLogo = styled.img`
  width: 360px;
  height: 157px;
`;

const StApp = styled.div`
  width: 100%;
`;

const StMainContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 60px;
  display: flex;
`;

interface Main {
  width: string;
}

const StMain = styled.main<Main>`
  // width: calc(100% - 280px);
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  overflow: scroll;
  background: ${theme.colors.bgDark};
`;

const StHome = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // overflow: scroll;
`;

const Home = () => {
  const [clickMenu, setClickMenu] = useState(false);
  const location = useLocation().pathname;
  const dispatch = useDispatch();
  const userInfo = useAppSelector((state) => state.userInfo);

  const onMenuClick = () => {
    setClickMenu(!clickMenu);
  };

  useEffect(() => {
    dispatch(userInfoActions.getUserInfo());
  }, []);

  return (
    <AppLayout>
      <StApp>
        <Header onMenuClick={onMenuClick} />
        <StMainContainer>
          <Navigation clickMenu={clickMenu} />
          <StMain width={clickMenu ? "100%" : "calc(100% - 280px)"}>
            {location === "/" ? (
              <StHome>
                <StLogo src="/imgs/main/main_logo.png" alt="main_logo" />
              </StHome>
            ) : (
              <Outlet />
            )}
            <Footer />
          </StMain>
        </StMainContainer>
      </StApp>
    </AppLayout>
  );
};

export default Home;
