import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  StAllCountContainer,
  StArrowBtn,
  StControllBottom,
  StPageTitle,
  StPaginationContainer,
  StRightSection,
  StSearchButton,
  StStatusItemText,
} from "../../components/CommonStyled/ComonStyled";
import ContainerLayout from "../../components/Layout/ContainerLayout/ContainerLayout";
import { alertAction } from "../../redux/features/modals/alertModal";
import { useAppDispatch } from "../../redux/store/store";
import contactList from "../../services/contact/contactList";
import DatePicker from "react-datepicker";
import { theme } from "../../styles/theme";
import EmptyList from "../../components/EmptyList/EmptyList";
import Pagination from "react-js-pagination";
import changeDate from "../../services/date/changeDate";

const StContactContainer = styled.div`
  width: 1560px;
  height: 100%;
  padding: 22px 24px 0px 40px;
  border-radius: 16px;
  background: ${theme.colors.bgWhite};
`;

const StControllContainer = styled.ul`
  margin-bottom: 38px;
  display: flex;
  align-items: center;
`;

const StPeriodWord = styled.li`
  width: 100px;
  margin-right: 24px;
  ${theme.font.medium.fontSize16};
`;

const StDateContainer = styled.div`
  margin-right: 32px;
  display: flex;
`;

interface ControllItem {
  width?: string;
  height?: string;
  margin?: string;
}

const StControllItem = styled.li<ControllItem>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
`;

const StDatePicker = styled(DatePicker)`
  width: 118px;
  height: 40px;
  padding: 9px 16px;
  border: 1px solid ${theme.colors.grayLine};
  border-radius: 4px;
`;

const StWave = styled.img`
  width: 16px;
  height: 40px;
  margin: 0 18px;
`;

const StWord = styled.h4`
  ${theme.font.regular.fontSize18};
`;

interface Input {
  width?: string;
  height?: string;
  padding?: string;
  fontSize?: string;
  fontWeight?: string;
}

const StControllInput = styled.input<Input>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  padding: ${({ padding }) => padding};
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ fontWeight }) => fontWeight};
  border: 1px solid ${theme.colors.grayLine};
  border-radius: 4px;

  &[type="checkbox"] {
    display: none;
  }

  &[type="checkbox"] + label {
    position: relative;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    display: inline-block;
    border: 1px solid ${theme.colors.grayLine};
    border-radius: 11px;
  }

  &[id="check1"]:checked + label::after {
    font-size: ${({ fontSize }) => fontSize}px;
    height: ${({ height }) => height}px;
    position: absolute;
    content: "";
    top: 3px;
    left: 3px;
    width: 14px;
    height: 14px;
    border-radius: 11px;
    background-size: cover;
    background-image: url("/imgs/controllBox/checkbox_active.png");
    background-color: ${theme.colors.bgWhite};
  }
`;

const StControllSelect = styled.select`
  width: 126px;
  height: 40px;
  padding: 0 9px 0 16px;
  border: 1px solid ${theme.colors.grayLine};
  appearance: none;
  border-radius: 4px;
  background: url("../imgs/controllBox/select_dropdown.png") no-repeat right
    16px center;
`;

const StTable = styled.ul`
  width: 1484px;
  min-height: 680px;
  margin-bottom: 43px;
`;

const StStatusList = styled.ul`
  width: 1480px;
  height: 60px;
  margin-bottom: 21px;
  display: flex;
  align-items: center;
  background: ${theme.colors.bgLight};
`;

interface StatusItem {
  width: string;
  margin: string;
  height?: string;
}

const StStatusItem = styled.li<StatusItem>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  margin: ${({ margin }) => margin};
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > img {
    width: 12px;
    height: 12px;
  }
`;

const StBtn = styled.button`
  width: 142px;
  height: 44px;
  padding: 9px 35px;
  border-radius: 4px;
  background: ${theme.colors.black};
  color: ${theme.colors.bgWhite};
  ${theme.font.medium.fontSize18};
`;

type List = {
  answerId: number;
  answerName: string;
  answerTime: string;
  authorId: string;
  authorName: string;
  postId: number;
  postTime: string;
  spServiceName: string;
  title: string;
  updateTime: string;
  answerStatus: string;
};

type Info = {
  type?: string;
  start?: string;
  end?: string;
  page?: number;
  sort?: string;
  sortOrder?: string;
  pageLimit?: number;
};

type Page = {
  currentPage: number;
  pageLimit: number;
  totalCount: number;
  totalPages: number;
};

const Contact = () => {
  const dateRef = useRef<any>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<any>(
    new Date(new Date(new Date().setDate(new Date().getDate() - 90)))
  );
  const [endDate, setEndDate] = useState<any>(new Date());
  const [page, setPage] = useState<Page>({
    totalCount: 0,
    totalPages: 0,
    currentPage: 1,
    pageLimit: 10,
  });
  const [info, setInfo] = useState<Info>({
    type: "all",
    start: startDate,
    end: endDate,
  });

  const [typeList, setTypeList] = useState({
    name: [
      {
        name: "No.",
        width: "56",
        height: "20",
        margin: "0 32px 0 16px",
        sort: "post_id",
      },
      {
        name: "서비스명",
        width: "176",
        height: "20",
        margin: "0 32px 0 0",
        sort: "sp_service_name",
      },
      {
        name: "제목",
        width: "216",
        height: "20",
        margin: "0 32px 0 0",
        sort: "title",
      },
      {
        name: "작성일시",
        width: "176",
        height: "20",
        margin: "0 32px 0 0",
        sort: "insert_dttm",
      },
      {
        name: "수정일시",
        width: "176",
        height: "20",
        margin: "0 32px 0 0",
        sort: "update_dttm",
      },
      {
        name: "답변일시",
        width: "176",
        height: "20",
        margin: "0 32px 0 0",
        sort: "answer_update_dttm",
      },
      {
        name: "답변자",
        width: "116",
        height: "20",
        margin: "0 32px 0 0",
        sort: "answer_name",
      },
      {
        name: "답변현황",
        width: "116",
        height: "20",
        margin: "0 32px 0 0",
        sort: "answer_status",
      },
    ],
    isOrder: "asc",
  });

  const [list, setList] = useState([]);

  const getContact = async () => {
    const { statusCode, data, message } = await contactList(
      info,
      page.currentPage
    );

    if (statusCode === 200) {
      const { inquiryList, ...others } = await data;

      setPage({ ...page, ...others });
      setList(inquiryList);
    } else {
      dispatch(alertAction.openModal({ modalName: "alert", text: message }));
    }
  };

  const onChangeDate = (type: string, date: any) => {
    if (type === "start") {
      setStartDate(date);
      setInfo({ ...info, [type]: date });
    } else {
      setEndDate(date);
      setInfo({ ...info, [type]: date });
    }
  };

  const handlePageChange = (clickedNumber: number) => {
    setPage({ ...page, currentPage: clickedNumber });
  };

  const onType = (
    event:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    const name = event.target.name;

    if (name === "pageLimit") {
      setInfo({ ...info, [name]: Number(value) });
      setPage({ ...page, currentPage: 1 });
    } else {
      setInfo({ ...info, [name]: value });
    }
  };

  const onSort = (text: string) => {
    if (text === "no") {
      return;
    } else {
      if (typeList.isOrder === "asc") {
        setTypeList({ ...typeList, isOrder: "desc" });
        setInfo({ ...info, sort: text, sortOrder: "desc" });
      } else {
        setTypeList({ ...typeList, isOrder: "asc" });
        setInfo({ ...info, sort: text, sortOrder: "asc" });
      }
    }
  };

  const onResultAction = (
    type: any,
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    if (type) {
      if (type.type === "edit") {
        const path = `/contact/post/${type.postId}`;
        navigate(path, { state: { postId: type.postId } });
      } else {
        const text = event.currentTarget.innerText;
        if (text === "Y") {
          const path = `/contact/answer/${type.postId}`;
          navigate(path, { state: { postId: type.postId } });
        } else {
          return;
        }
      }
    } else {
      return;
    }
  };

  const resultList = list.map((item: List) => {
    const {
      answerId,
      answerName,
      answerTime,
      authorId,
      authorName,
      postId,
      postTime,
      spServiceName,
      title,
      updateTime,
      answerStatus,
    } = item;
    const arr = [
      { name: postId, width: "56", height: "20", margin: "0 32px 0 16px" },
      { name: spServiceName, width: "176", height: "20", margin: "0 32px 0 0" },
      {
        name: title.length > 60 ? `${title.slice(0, 60)}...` : title,
        width: "216",
        height: "20",
        margin: "0 32px 0 0",
        data: { type: "edit", postId },
      },
      { name: postTime, width: "176", height: "20", margin: "0 32px 0 0" },
      { name: updateTime, width: "176", height: "20", margin: "0 32px 0 0" },
      { name: answerTime, width: "176", height: "20", margin: "0 32px 0 0" },
      { name: answerName, width: "116", height: "20", margin: "0 32px 0 0" },
      {
        name: answerStatus,
        width: "116",
        height: "20",
        margin: "0 32px 0 0",
        data: { type: "answer", postId },
      },
    ];

    return arr;
  });

  useEffect(() => {
    getContact();
  }, [page.currentPage, info.pageLimit, info.sortOrder]);

  return (
    <ContainerLayout>
      <StPageTitle>문의하기</StPageTitle>

      <StContactContainer>
        <StControllContainer>
          <StPeriodWord>조회기간</StPeriodWord>

          <StDateContainer>
            <StDatePicker
              dateFormat="yyyy-MM-dd"
              selected={startDate}
              onChange={(date) => onChangeDate("start", date)}
              startDate={startDate}
              endDate={endDate}
            />
            <StWave src="/imgs/calendar/~.png" alt="~" />
            <StDatePicker
              dateFormat="yyyy-MM-dd"
              selected={endDate}
              onChange={(date) => onChangeDate("end", date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
          </StDateContainer>
          <StRightSection>
            <StControllItem margin="0 16px 0 0">
              <StControllSelect
                name="type"
                defaultValue={"type"}
                onChange={(event) => onType(event)}
              >
                <option value="all">전체</option>
                <option value="title">제목</option>
                <option value="service">서비스명</option>
              </StControllSelect>
            </StControllItem>
            <StControllItem margin="0 24px 0 0">
              <StControllInput
                name="search"
                width="368"
                height="40"
                padding="9px 16px"
                fontSize="16"
                fontWeight="400"
                type="text"
                placeholder="검색어를 입력해주세요."
                onChange={(event) => onType(event)}
              />
            </StControllItem>
            <li>
              <StSearchButton onClick={getContact}>검색</StSearchButton>
            </li>
          </StRightSection>
        </StControllContainer>

        <StAllCountContainer>
          <span>전체</span>
          <h4>{page.totalCount}건</h4>
        </StAllCountContainer>

        <StTable>
          {resultList.length === 0 ? (
            <EmptyList />
          ) : (
            <>
              <StStatusList>
                {typeList.name.map((item, index) => {
                  return (
                    <StStatusItem
                      key={`${item.name} + ${index} + ${Math.random()}`}
                      width={item.width}
                      margin={item.margin}
                      height={item.height}
                      onClick={() => onSort(item.sort)}
                    >
                      <StStatusItemText>{item.name}</StStatusItemText>
                      <img src="/imgs/table/status_down.png" alt="down" />
                    </StStatusItem>
                  );
                })}
              </StStatusList>

              {resultList.map((item, i) => {
                const result = item.map((item, index) => {
                  return (
                    <StStatusItem
                      key={`${item.name} + ${index} + ${i} + ${Math.random()}`}
                      width={item.width}
                      margin={item.margin}
                      height={item.height}
                      onClick={(event) => onResultAction(item.data, event)}
                    >
                      {item.data?.type === "edit" ? (
                        <StStatusItemText
                          style={{
                            cursor: "pointer",
                            borderBottom: "1px solid",
                          }}
                        >
                          {item.name}
                        </StStatusItemText>
                      ) : (
                        <StStatusItemText>{item.name}</StStatusItemText>
                      )}
                    </StStatusItem>
                  );
                });
                return (
                  <ul
                    key={`${item} + ${Math.random()}`}
                    style={{
                      display: "flex",
                      height: "60px",
                      alignItems: "center",
                    }}
                  >
                    {result}
                  </ul>
                );
              })}
            </>
          )}
        </StTable>

        <StControllBottom>
          <li>
            <StBtn onClick={() => navigate("/contact/post")}>문의하기</StBtn>
          </li>

          <li>
            <StPaginationContainer>
              <Pagination
                activePage={page.currentPage}
                itemsCountPerPage={page.pageLimit}
                totalItemsCount={page.totalCount}
                pageRangeDisplayed={5}
                prevPageText={"‹"}
                nextPageText={"›"}
                onChange={handlePageChange}
              />
            </StPaginationContainer>
          </li>

          <li>
            <StControllSelect
              name="pageLimit"
              defaultValue={10}
              onChange={(event) => onType(event)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </StControllSelect>
            <span style={{ marginLeft: "8px" }}>개씩 보기</span>
          </li>
        </StControllBottom>
      </StContactContainer>
    </ContainerLayout>
  );
};

export default Contact;
