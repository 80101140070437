import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ActionAlert from "./components/Alert/ActionAlert";
import Alert from "./components/Alert/Alert";
import ChangePwAlert from "./components/Alert/ChangePwAlert";
import Home from "./components/Home/Home";
import NotFound from "./components/NotFound/NotFound";
import Contact from "./pages/Contact/Contact";
import ContactAnswer from "./pages/Contact/ContactAnswer";
import ContactPost from "./pages/Contact/ContactPost";
import EditPost from "./pages/Contact/EditPost";
import SelectPost from "./pages/Contact/SelectPost";
import Dashboard from "./pages/Dashboard/Dashboard";
import ClientInfo from "./pages/Info/ClientInfo";
import EditClientInfo from "./pages/Info/EditClientInfo";
import ServiceInfo from "./pages/Info/ServiceInfo";
import Invoice from "./pages/Invoice/Invoice";
import InvoiceContainer from "./pages/Invoice/InvoiceContainer";
import Login from "./pages/Login/Login";
import Notice from "./pages/Notice/Notice";
import SelectNotice from "./pages/Notice/SelectNotice";
import AllUse from "./pages/Use/AllUse";
import ApiUse from "./pages/Use/ApiUse";
import MyPage from "./pages/UserInfo/MyPage/MyPage";
import UserEdit from "./pages/UserInfo/UserEdit/UserEdit";
import Modal from "./portals/Modals";
import { useAppSelector } from "./redux/store/store";

interface Props {
  accessToken?: string;
  idToken?: string;
  refreshToken?: string;
  sessionToken?: string;
}

const App: React.FC<Props> = () => {
  const accessToken = window.sessionStorage.getItem("accessToken");
  const idToken = window.sessionStorage.getItem("idToken");
  const refreshToken = window.sessionStorage.getItem("refreshToken");
  const sessionToken = window.sessionStorage.getItem("sessionToken");
  const { modal, modalName } = useAppSelector((state) => state.alertModal);

  const { actionModal, actionModalName } = useAppSelector(
    (state) => state.actionModal
  );
  const { pwChangeModal, pwChangeModalName } = useAppSelector(
    (state) => state.pwChangeModal
  );

  const modalstate = () => {
    switch (modalName) {
      case "alert":
        return <Alert />;

      default:
        break;
    }
  };

  const actionModalstate = () => {
    switch (actionModalName) {
      case "action":
        return <ActionAlert />;

      default:
        break;
    }
  };

  const changePwModalstate = () => {
    switch ("change_pw") {
      case "change_pw":
        return <ChangePwAlert />;

      default:
        break;
    }
  };

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={idToken ? <Home /> : <Navigate replace to="/login" />}
        >
          <Route path="user">
            <Route path="/user" element={<NotFound />} />
            <Route path="mypage" element={<MyPage />} />
            <Route path="edit" element={<UserEdit />} />
          </Route>

          <Route path="dashboard">
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>

          <Route path="use">
            <Route path="/use" element={<NotFound />} />
            <Route path="all" element={<AllUse />} />
            <Route path="api" element={<ApiUse />} />
          </Route>

          <Route path="invoice">
            <Route path="/invoice" element={<InvoiceContainer />} />
          </Route>

          <Route path="notice">
            <Route path="/notice" element={<Notice />} />
            <Route path="/notice/:id" element={<SelectNotice />} />
          </Route>

          <Route path="contact">
            <Route path="/contact" element={<Contact />} />
            <Route path="/contact/post" element={<ContactPost />} />
            <Route path="/contact/post/:id" element={<SelectPost />} />
            <Route path="/contact/post/:id/edit" element={<EditPost />} />
            <Route path="/contact/answer/:id" element={<ContactAnswer />} />
          </Route>

          <Route path="info">
            <Route path="/info" element={<NotFound />} />
            <Route path="client" element={<ClientInfo />} />
            <Route path="/info/client/edit" element={<EditClientInfo />} />
            <Route path="service" element={<ServiceInfo />} />
          </Route>
        </Route>

        <Route
          path="/login"
          element={idToken ? <Navigate replace to="/" /> : <Login />}
        />

        <Route
          path="/*"
          element={idToken ? <NotFound /> : <Navigate replace to="/login" />}
        />
      </Routes>
      {modal && <Modal portalName={modalName}>{modalstate()}</Modal>}
      {actionModal && (
        <Modal portalName={actionModalName}>{actionModalstate()}</Modal>
      )}
      {sessionToken && (
        <Modal portalName={"change_pw"}>{changePwModalstate()}</Modal>
      )}
    </>
  );
};

export default App;
