import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Modal {
  pwChangeModal: boolean;
  pwChangeModalName: string;
  text?: string;
  value?: string | number | object;
  store?: any;
}

const initialState: Modal = {
  pwChangeModal: false,
  pwChangeModalName: "",
  text: "",
  value: "",
  store: [],
};

const pwChangeModal = createSlice({
  name: "change_pw",
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{
        modalName: string;
        text?: string;
        value?: any;
        store?: any;
      }>
    ) => {
      state.pwChangeModal = true;
      state.pwChangeModalName = action.payload.modalName;
      state.text = action.payload.text || "";
      state.value = action.payload.value;
      state.store = action.payload.store;
    },
    closeModal: (state) => {
      state.pwChangeModal = false;
      state.pwChangeModalName = "";
      state.text = "";
    },
  },
});

export const pwChangeAlert = pwChangeModal.actions;

export default pwChangeModal;
