import React from "react";
import { StPageTitle } from "../../components/CommonStyled/ComonStyled";
import ApiUseCostGraph from "../../components/Graph/ApiUseCostGraph";
import ApiUseGraph from "../../components/Graph/ApiUseGraph";
import ContainerLayout from "../../components/Layout/ContainerLayout/ContainerLayout";

const ApiUse = () => {
  return (
    <ContainerLayout>
      <StPageTitle>API 사용량</StPageTitle>

      <ApiUseGraph />
      <ApiUseCostGraph />
    </ContainerLayout>
  );
};

export default ApiUse;
