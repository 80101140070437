import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

type UserData = {
  id: string;
  name: string;
  department: string;
  position: string;
  phoneNumber: string;
  corpName: string;
  os: string;
  ip: string;
  noticeIdx: [];
  service: number[];
  signUpDttm: string;
  lastLoginDttm: string;
};

interface UserState {
  loading: boolean;
  data: UserData;
  error: AxiosError | null;
}

const initialState: UserState = {
  loading: false,
  data: {
    id: "",
    name: "",
    department: "",
    position: "",
    phoneNumber: "",
    corpName: "",
    os: "",
    ip: "",
    noticeIdx: [],
    service: [],
    signUpDttm: "",
    lastLoginDttm: "",
  },
  error: null,
};

const userInfo = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    // 액션에 따른 reducer 로직을 작성한다.
    // createSlice가 자동으로 state의 타입을 추론한다.
    // 또한 immer를 사용하고 있어 함수 몸체 안에서 직접 변경해도 불변성을 유지한다.
    getUserInfo: (state) => {
      // loading => true
      state.loading = true;
    },
    getUserInfoSuccess: (state, action: PayloadAction<UserData>) => {
      // API 요청이 성공적이면 데이터를 payload에 들어온다.
      state.data = action.payload;
      state.loading = false;
    },
    getUserInfoError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

// 정의한 액션과 리듀서를 export한다.
export const userInfoActions = userInfo.actions;
export default userInfo;
