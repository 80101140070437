import axios, { AxiosPromise } from "axios";
import refresh from "../auth/refresh/refresh";
import changeDate from "../date/changeDate";

interface Info {
  page: number;
  item?: string;
  search?: string;
  sort?: "asc" | "desc";
  pageLimit?: 10 | 20 | 50;
  start?: string;
  end?: string;
}

interface NoticeList {
  (info: Info): AxiosPromise;
}

const noticeList: any = async (info: Info, page: number) => {
  const accessToken = window.sessionStorage.getItem("accessToken");
  const idToken = window.sessionStorage.getItem("idToken");

  try {
    const response = await axios({
      url: "/noticeborad/member/notice",
      method: "get",
      headers: {
        Accept: `application/json`,
        Authorization: `Bearer ${idToken}`,
        "Access-Token": accessToken,
      },
      params: {
        ...info,
        start: changeDate(info.start),
        end: changeDate(info.end),
        page,
      },
    });
    return response.data;
  } catch (error: any) {
    const statusCode = error.response.data.statusCode;
    if (statusCode === 401) {
      await refresh();
      return noticeList(info, page);
    } else {
      return error.response.data;
    }
  }
};

export default noticeList;
