import React, { useState } from "react";
import styled from "styled-components";
import { modalAction } from "../../redux/features/modals/actionModals";
import { alertAction } from "../../redux/features/modals/alertModal";
import { pwChangeAlert } from "../../redux/features/modals/pwModals";
import { useAppDispatch, useAppSelector } from "../../redux/store/store";
import resetPw from "../../services/user/resetPw";
import { theme } from "../../styles/theme";
import PortalLayout from "../PortalLayout/PortalLayout";

const StAlert = styled.div`
  width: 677px;
  height: 390px;
  padding: 32px;
  background: ${theme.colors.bgWhite};
  border: 1px solid #dcdcdc;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
`;

const StText = styled.p`
  margin-bottom: 24px;
  ${theme.font.medium.fontSize18};
`;

const StList = styled.ul``;

const StListItem = styled.li`
  height: 56px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;

  & > label {
    width: 86px;
    height: 44px;
    margin-right: 24px;
    ${theme.font.medium.fontSize16};
    corlor: #777777;
  }

  & > input {
    width: 500px;
    height: 56px;
    padding: 16px;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 4px;
  }
`;

const StBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StAlertButton = styled.button`
  width: 120px;
  height: 40px;
  border-radius: 4px;
  background: #7fc41c;
  border-radius: 4px;
  color: ${theme.colors.bgWhite};
  ${theme.font.medium.fontSize18};
`;

const ChangePwAlert = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");
  const [chekPw, setChekPw] = useState("");

  const modalClose = async () => {
    const regPw =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;

    if (pw === chekPw) {
      if (regPw.test(pw)) {
        const info = { user_id: email, new_password: pw };
        const { statusCode, data, message } = await resetPw(info);

        if (statusCode === 200) {
          dispatch(pwChangeAlert.closeModal());
          dispatch(
            modalAction.openModal({
              modalName: "alert",
              text: message,
              path: "/",
            })
          );
          window.sessionStorage.clear();
        } else {
          dispatch(
            alertAction.openModal({ modalName: "alert", text: message })
          );
        }
      } else {
        dispatch(
          alertAction.openModal({
            modalName: "alert",
            text: `"비밀번호는 영문, 숫자, 특수 문자 3종류 이상 및 8자리 이상이어야 합니다.`,
          })
        );
      }
    } else {
      dispatch(
        alertAction.openModal({
          modalName: "alert",
          text: `비밀번호를 확인해주세요.`,
        })
      );
    }
  };

  const onClose = () => {
    window.sessionStorage.clear();
    window.location.href = "/";
    // dispatch(pwChangeAlert.closeModal());
  };

  return (
    <PortalLayout zIndex={1}>
      <StAlert>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <StText>{`임시 비밀번호 변경`}</StText>
          <img
            width={20}
            height={20}
            src="/imgs/login/close.png"
            alt="close"
            onClick={onClose}
          />
        </div>

        <StList>
          <StListItem>
            <label htmlFor="email">이메일 확인</label>
            <input
              type="email"
              onChange={(event) => setEmail(event.target.value)}
            />
          </StListItem>

          <StListItem>
            <label htmlFor="pw">변경 비밀번호</label>
            <input
              type="password"
              onChange={(event) => setPw(event.target.value)}
            />
          </StListItem>

          <StListItem>
            <label htmlFor="chekPw">변경 비밀번호 확인</label>
            <input
              type="password"
              onChange={(event) => setChekPw(event.target.value)}
            />
          </StListItem>
        </StList>

        <StBtnContainer>
          <StAlertButton onClick={modalClose}>확인</StAlertButton>
        </StBtnContainer>
      </StAlert>
    </PortalLayout>
  );
};

export default ChangePwAlert;
