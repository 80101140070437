import axios from "axios";
import refresh from "../auth/refresh/refresh";

const dashboardTop: any = async (date: string) => {
  const idToken = window.sessionStorage.getItem("idToken");
  try {
    const response = await axios({
      url: "/dashboard/member/top-line",
      method: "get",
      headers: {
        accept: `application/json`,
        Authorization: `Bearer ${idToken}`,
      },
      params: {
        date,
      },
    });

    return response.data;
  } catch (error: any) {
    const statusCode = error.response.data.statusCode;
    if (statusCode === 401) {
      await refresh();
      return dashboardTop(date);
    } else {
      return error.response.data;
    }
  }
};

export default dashboardTop;
