import axios, { AxiosPromise } from "axios";
import refresh from "../auth/refresh/refresh";

interface Info {
  start: string;
  end: string;
  spServiceName: string;
  page: number;
  pageLimit: 10 | 20 | 50;
  sort: "id" | "invoice_name" | "std_dt" | "cost";
  sort_order: "asc" | "desc";
}

interface Invoice {
  (info: Info): AxiosPromise;
}

const invoice: any = async (info: Info) => {
  const accessToken = window.sessionStorage.getItem("accessToken");
  const idToken = window.sessionStorage.getItem("idToken");

  try {
    const response = await axios({
      url: "/invoice/member",
      method: "get",
      headers: {
        Accept: `application/json`,
        Authorization: `Bearer ${idToken}`,
        "Access-Token": accessToken,
      },
      params: {
        ...info,
      },
    });

    return response.data;
  } catch (error: any) {
    const statusCode = error.response.data.statusCode;
    if (statusCode === 401) {
      await refresh();
      return invoice(info);
    } else {
      return error.response.data;
    }
  }
};

export default invoice;
