import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { StPageTitle } from "../../components/CommonStyled/ComonStyled";
import ContainerLayout from "../../components/Layout/ContainerLayout/ContainerLayout";
import { alertAction } from "../../redux/features/modals/alertModal";
import { useAppDispatch, useAppSelector } from "../../redux/store/store";
import dashboardTop from "../../services/dashboard/dashboardTop";
import serviceList from "../../services/user/serviceList";
import { theme } from "../../styles/theme";
import {
  changeApiCost,
  changeCost,
  numberTo,
} from "../../services/common/changeCost";
import ServiceUseGraph from "../../components/Graph/ServiceUseGraph";
import ApiUseGraph from "../../components/Graph/ApiUseGraph";
import ApiUseCostGraph from "../../components/Graph/ApiUseCostGraph";
import MonthServiceTotalGraph from "../../components/Graph/MonthServiceTotalGraph";
import ScaleLoader from "react-spinners/ScaleLoader";
import PortalLayout from "../../components/PortalLayout/PortalLayout";

const StTitleContainer = styled.div`
  width: 1560px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;

  & > select {
    width: 160px;
    padding: 9px 16px;
    height: 40px;
    border: 1px solid ${theme.colors.grayLine};
    appearance: none;
    border-radius: 4px;

    background: url("../imgs/controllBox/select_dropdown.png") no-repeat right
      16px center;
    background-color: ${theme.colors.bgWhite};
  }
`;

const StCardContainer = styled.ul`
  width: 1560px;
  display: flex;
`;

const StCard = styled.li`
  width: 215px;
  height: 140px;
  margin-right: 8px;
  padding: 16px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background: ${theme.colors.bgWhite};
  box-shadow: 4px 4px 10px rgba(159, 171, 189, 0.15);
  border-radius: 8px;
`;

const StCardLabel = styled.h4`
  margin-bottom: 4;
  ${theme.font.bold.fontSize16};
`;

const StCardDate = styled.h4`
  margin-bottom: 24px;
  color: #acacac;
  ${theme.font.regular.fontSize14};
`;

const StCardValue = styled.h4`
  position: relative;
  width: 100%;
  text-align: end;
  ${theme.font.medium.fontSize26};
`;

interface Hover {
  top?: string;
  right?: string;
  left?: string;
  bottom?: string;
}

const StHoverContainer = styled.div<Hover>`
  position: absolute;
  top: 30px;
  left: 30px;
  width: 160px;
  height: 79px;
  display: flex;
  flex-direction: column;
`;

const StHoverTopImg = styled.img`
  width: 11px;
  height: 11px;
`;

const StHoverPar = styled.p`
  position: absolute;
  top: 9px;
  left: 6px;
  width: 100%;
  height: 100%;
  padding: 6px 16px;
  background: #8fc325;
  border-radius: 6px;
  color: ${theme.colors.bgWhite};
  word-break: break-all;
  text-align: left;
  ${theme.font.regular.fontSize14};
  overflow: hidden;
  z-index: 1000;
`;

interface DataList {
  key: string;
  value: string;
}

type GraphDataDay = {
  stdDt: string;
  value: number;
};

type GraphDataWeek = {
  week: string;
  stats: {
    stdDt: string;
    days: {
      stdDt: string;
      value: number;
    }[];
  };
};

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const service = useAppSelector((state) => state.userInfo.data.service);
  const [dateList, setDateList] = useState<DataList[]>([]);
  const [topList, setTopList] = useState({
    maxCall: { day: "000000", callCount: 0 },
    prevMonthCall: 0,
    prevMonthCost: 0,
    prevMonth: "",
    thisMonth: "",
    serviceInfo: {
      serviceCount: 0,
      serviceList: [],
    },
    thisMonthCall: 0,
    thisMonthCost: 0,
    yearTotalCall: 0,
  });
  const [cardTooltip, setCardTooltip] = useState([]);

  const cardList = [
    {
      label: "서비스",
      value: topList.serviceInfo.serviceCount,
      full: cardTooltip.length
        ? cardTooltip.length > 30
          ? `${cardTooltip.join().slice(0, 30)}... 이용중`
          : cardTooltip.join()
        : "사용중인 서비스가 없습니다.",
    },
    {
      label: `${topList.thisMonth.slice(0, 4)} 누적 Call`,
      value: numberTo(topList.yearTotalCall),
      full: changeApiCost(topList.yearTotalCall),
    },
    {
      label: `${topList.prevMonth.slice(0, 4)}/${topList.prevMonth.slice(
        -2
      )}(전월) Call `,
      value: numberTo(topList.prevMonthCall),
      full: changeApiCost(topList.prevMonthCall),
    },
    {
      label: `${topList.thisMonth.slice(0, 4)}/${topList.thisMonth.slice(
        -2
      )}(당월) Call `,
      value: numberTo(topList.thisMonthCall),
      full: changeApiCost(topList.thisMonthCall),
    },
    {
      label: `${topList.maxCall.day.slice(0, 4)}/${topList.maxCall.day.slice(
        4,
        6
      )}(당월) Max Call`,
      value: `${topList.maxCall.day.slice(-2)}일 ${numberTo(
        topList.maxCall.callCount
      )}`,
      full: changeApiCost(topList.maxCall.callCount),
    },
    {
      label: `${topList.prevMonth.slice(0, 4)}/${topList.prevMonth.slice(
        -2
      )}(전월) 비용 `,
      value: `${changeCost(topList.prevMonthCost)}`,
    },
    {
      label: `${topList.thisMonth.slice(0, 4)}/${topList.thisMonth.slice(
        -2
      )}(당월) 비용 `,
      value: `${changeCost(topList.thisMonthCost)}`,
    },
  ];

  const [hover, setHover] = useState([
    {
      index: 0,
      name: "service",
      isHover: false,
    },
    {
      index: 1,
      name: "yearTotalCall",
      isHover: false,
    },
    {
      index: 2,
      name: "prevMonthCall",
      isHover: false,
    },
    {
      index: 3,
      name: "thisMonthCall",
      isHover: false,
    },
    {
      index: 4,
      name: "maxCall",
      isHover: false,
    },
  ]);

  const firstGetTopDate = async () => {
    const { data, statusCode, message } = (await serviceList()).data;

    setDateList([]);

    if (statusCode === 200) {
      data.map((item: string) => {
        const year = item.slice(0, 4);
        const month = item.slice(4, 6);
        setDateList((pre) => [
          ...pre,
          { key: `${year}-${month}`, value: item },
        ]);
      });
    } else {
      dispatch(alertAction.openModal({ modalName: "alert", text: message }));
    }
  };

  const onDashTopDate = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;

    const { statusCode, data, message } = await dashboardTop(value);

    if (statusCode === 200) {
      const dataList = data;

      setTopList(dataList);
      setCardTooltip(dataList.serviceInfo.serviceList);
    } else {
      dispatch(alertAction.openModal({ modalName: "alert", text: message }));
    }
  };

  const getServiceList = async () => {
    const value = dateList.filter(
      (item, index) => index === dateList.length - 1
    );

    if (value[0]) {
      const { statusCode, data, message } = await dashboardTop(value[0].value);

      if (statusCode === 200) {
        const dataList = data;
        setTopList(dataList);
        setCardTooltip(dataList.serviceInfo.serviceList);
      } else {
        dispatch(alertAction.openModal({ modalName: "alert", text: message }));
      }
    } else {
      return;
    }
  };

  const isHover = (index: number) => {
    let findIndex = hover.findIndex((item) => item.index === index);

    let copiedItems = [...hover];
    /* 새로운 변수를 선언해 기존의 배열을 복사하는 과정을 거쳐야 한다.
    useState로 만든 변수는 set함수로만 값을 변경할 수 있기 때문이다. */

    copiedItems[findIndex].isHover = !copiedItems[findIndex]?.isHover;
    /* 복사한 배열에 수정할 값을 할당하고 */

    setHover(copiedItems);
    /* setItems를 사용해 수정한 배열로 items를 업데이트 한다. */
  };

  useEffect(() => {
    firstGetTopDate();
  }, []);

  useEffect(() => {
    getServiceList();
  }, [dateList]);

  return (
    <ContainerLayout>
      {dateList.length === 0 ? (
        <PortalLayout zIndex={100}>
          <ScaleLoader color="#7FC41C" height={100} width={10} />
        </PortalLayout>
      ) : (
        <>
          <StTitleContainer>
            <StPageTitle>Dashboard</StPageTitle>
            <select name="data" onChange={(event) => onDashTopDate(event)}>
              {dateList.map((item, index) => {
                return (
                  <option
                    value={item.value}
                    key={item.key}
                    selected={index + 1 === dateList.length}
                  >
                    {item.key}
                  </option>
                );
              })}
            </select>
          </StTitleContainer>
          <StCardContainer>
            {cardList.map((item, index) => (
              <StCard key={index}>
                <StCardLabel>{item.label}</StCardLabel>
                <StCardValue
                  onMouseOver={() => isHover(index)}
                  onMouseOut={() => isHover(index)}
                >
                  {item.value}

                  {hover[index]?.isHover && (
                    <StHoverContainer>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginRight: "5px",
                        }}
                      >
                        <StHoverTopImg
                          src="/imgs/tooltip/tooltip_top.png"
                          alt="tooltip_top"
                        />
                      </div>
                      <StHoverPar>{item.full}</StHoverPar>
                    </StHoverContainer>
                  )}
                </StCardValue>
              </StCard>
            ))}
          </StCardContainer>
        </>
      )}

      {/* graph */}

      <ServiceUseGraph />
      <MonthServiceTotalGraph />
      <ApiUseGraph />
      <ApiUseCostGraph />
    </ContainerLayout>
  );
};

export default Dashboard;
