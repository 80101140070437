import React from "react";
import styled from "styled-components";

const StLayoutContainer = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
`;

interface Props {
  children: React.ReactNode;
}

const AppLayout: React.FC<Props> = ({ children }) => {
  return <StLayoutContainer>{children}</StLayoutContainer>;
};

export default AppLayout;
