import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  StBtn,
  StBtnContainer,
  StPageTitle,
} from "../../components/CommonStyled/ComonStyled";
import ContainerLayout from "../../components/Layout/ContainerLayout/ContainerLayout";
import { alertAction } from "../../redux/features/modals/alertModal";
import { useAppDispatch } from "../../redux/store/store";
import selectNoticeList from "../../services/notice/selectNotice";
import { theme } from "../../styles/theme";

const StSelectNoticeContainer = styled.div`
  position: relative;
  width: 1560px;
  min-height: 938px;
  height: 100%;
  padding: 40px;
  border-radius: 12px;
  background: ${theme.colors.bgWhite};
`;

const StList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const StListUpItem = styled.li`
  width: 50%;
  margin-bottom: 40px;
  display: flex;
`;

const StListDownItem = styled.li`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
`;

const StLabel = styled.label`
  min-width: 80px;
  height: 22px;
  margin-right: 40px;
  ${theme.font.medium.fontSize16};
`;

const StPar = styled.p`
  ${theme.font.regular.fontSize16};
`;

const StPre = styled.pre`
  ${theme.font.regular.fontSize16};
`;

type ListItem = {
  admin_id: string;
  content: string;
  idx: number;
  insert_dttm: string;
  status: string;
  title: string;
  type: string;
  update_dttm: string;
  views: number;
};

type List = {
  name: string;
  value: string | number;
  key: string;
};

const SelectNotice = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const postId = useLocation().state.postId;
  const [list, setList] = useState<ListItem>();

  const getPost = async () => {
    const { statusCode, data, message } = await selectNoticeList(postId);

    if (statusCode === 200) {
      setList(data);
    } else {
      dispatch(
        alertAction.openModal({
          modalName: "alert",
          text: message,
        })
      );
    }
  };

  useEffect(() => {
    getPost();
  }, []);

  return (
    <ContainerLayout>
      <StPageTitle>공지사항</StPageTitle>

      <StSelectNoticeContainer>
        <StList>
          <StListUpItem>
            <StLabel htmlFor={`insert_dttm`}>작성일시</StLabel>
            <StPar>{list?.insert_dttm}</StPar>
          </StListUpItem>
          <StListUpItem>
            <StLabel htmlFor={`update_dttm`}>수정일시</StLabel>
            <StPar>{list?.update_dttm}</StPar>
          </StListUpItem>
        </StList>
        <StList
          style={{
            borderBottom: `1px solid ${theme.colors.grayLine}`,
            marginBottom: "30px",
          }}
        >
          <StListUpItem>
            <StLabel htmlFor={`admin_id`}>작성자</StLabel>
            <StPar>{list?.admin_id}</StPar>
          </StListUpItem>
          <StListUpItem>
            <StLabel htmlFor={`views`}>조회수</StLabel>
            <StPar>{list?.views}</StPar>
          </StListUpItem>
        </StList>

        <StList>
          <StListDownItem>
            <StLabel htmlFor={`title`}>제목</StLabel>
            <StPar>{list?.title}</StPar>
          </StListDownItem>
          <StListDownItem>
            <StLabel htmlFor={`content`}>내용</StLabel>
            <StPre>{list?.content}</StPre>
          </StListDownItem>
        </StList>

        <StBtnContainer
          position="absolute"
          bottom="40px"
          right="40px"
          justifyContent="end"
        >
          <StBtn onClick={() => navigate(`/notice`)}>확인</StBtn>
        </StBtnContainer>
      </StSelectNoticeContainer>
    </ContainerLayout>
  );
};

export default SelectNotice;
