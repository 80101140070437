import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { all } from "redux-saga/effects";
import createSagaMiddleware from "redux-saga";
import getUserInfoSaga from "../saga/userInfoSaga";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import userInfo from "../features/products/user/userInfo";
import alert from "../features/modals/alertModal";
import actionModal from "../features/modals/actionModals";
import pwChangeModal from "../features/modals/pwModals";

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  userInfo: userInfo.reducer,
  alertModal: alert.reducer,
  actionModal: actionModal.reducer,
  pwChangeModal: pwChangeModal.reducer,
});

function* rootSaga() {
  yield all([getUserInfoSaga()]);
}

function createStore() {
  // 여기서 store를 생성
  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware],
  });
  sagaMiddleware.run(rootSaga);
  return store;
}

const store = createStore();

// RootState type. useSelector 사용 시 state 타입을 위해 필요
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
