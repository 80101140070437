import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  StBtn,
  StBtnContainer,
  StPageTitle,
  StSubTitle,
} from "../../components/CommonStyled/ComonStyled";
import ContainerLayout from "../../components/Layout/ContainerLayout/ContainerLayout";
import { modalAction } from "../../redux/features/modals/actionModals";
import { alertAction } from "../../redux/features/modals/alertModal";
import { useAppDispatch } from "../../redux/store/store";
import clientInfo from "../../services/client/clientInfo";
import editClient from "../../services/client/editClient";
import { theme } from "../../styles/theme";

const StIEditClentInfoContainer = styled.div`
  width: 1560px;
  height: 412px;
  padding: 40px;
  border-radius: 16px;
  background: ${theme.colors.bgWhite};
`;

type ListItem = {
  spCorpAddress: string;
  spCorpEmail: string;
  spCorpName: string;
  spCorpNumber: string;
  spCorpWeburl: string;
};

type List = {
  name: string;
  value: string;
  key: string;
};

const StEditList = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  & > li {
    width: 45%;
    margin-bottom: 16px;
    margin-right: 37px;
    display: flex;

    & > label {
      display: flex;
      justify-content: center;
      align-items: center;

      & > span {
        width: 80px;
        height: 44px;
        margin-right: 24px;
        ${theme.font.medium.fontSize16};
        color: #777777;
      }
    }

    & > input {
      height: 56px;
      width: 560px;
      padding: 16px;
      border-radius: 4px;
      background: ${theme.colors.bgWhite};
      border: 1px solid ${theme.colors.grayLine};
    }

    & > input:last-child {
      width: 1264px;
      height: 56px;
    }
  }

  & > li:last-child {
    width: 100%;
  }
`;

const EditClientInfo = () => {
  const dispatch = useAppDispatch();
  const [list, setList] = useState<List[]>([]);
  const [info, setInfo] = useState([]);

  const getCompanyInfo = async () => {
    const { statusCode, data, message } = await clientInfo();

    if (statusCode === 200) {
      await data.map((item: ListItem) =>
        setList([
          { name: "회사명", value: item.spCorpName, key: "spCorpName" },
          {
            name: "회사 대표 전화번호",
            value: item.spCorpNumber,
            key: "spCorpNumber",
          },
          {
            name: "회사 대표 이메일",
            value: item.spCorpEmail,
            key: "spCorpEmail",
          },
          {
            name: "회사 홈페이지",
            value: item.spCorpWeburl,
            key: "spCorpWeburl",
          },
          {
            name: "회사 주소",
            value: item.spCorpAddress,
            key: "spCorpAddress",
          },
        ])
      );
    } else {
      dispatch(alertAction.openModal({ modalName: "alert", text: message }));
    }
  };

  const onEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    setInfo({ ...info, [name]: value });
  };

  const onSave = async () => {
    const { statusCode, message } = await editClient(info);

    if (statusCode === 200) {
      dispatch(
        modalAction.openModal({
          modalName: "action",
          text: message,
          path: "/info/client",
        })
      );
    } else {
      dispatch(alertAction.openModal({ modalName: "alert", text: message }));
    }
  };

  useEffect(() => {
    getCompanyInfo();
  }, []);

  return (
    <ContainerLayout>
      <StPageTitle>고객사 정보 수정</StPageTitle>
      <StIEditClentInfoContainer>
        <StSubTitle>고객사 정보 수정</StSubTitle>

        <StEditList>
          {list?.map((item, index) => {
            return (
              <li key={index}>
                <label htmlFor={item.key}>
                  <span>{item.name}</span>
                </label>
                {item.name === "회사명" ? (
                  <input
                    style={{ background: "#F3F3F3" }}
                    type="text"
                    placeholder={item.value}
                    value={item.value}
                    readOnly
                  />
                ) : (
                  <input
                    name={item.key}
                    type="text"
                    placeholder={item.value}
                    onChange={(event) => onEdit(event)}
                  />
                )}
              </li>
            );
          })}
        </StEditList>

        <StBtnContainer margin="24px 0 0 0" justifyContent="end">
          <StBtn onClick={onSave}>저장</StBtn>
        </StBtnContainer>
      </StIEditClentInfoContainer>
    </ContainerLayout>
  );
};

export default EditClientInfo;
