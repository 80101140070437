import React from "react";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import DatePicker from "react-datepicker";

export const StPageTitle = styled.h2`
  margin-bottom: 24px;
  ${theme.font.bold.fontSize26};
`;

export const StSubTitle = styled.h3`
  margin-bottom: 24px;
  ${theme.font.bold.fontSize18};
`;

export const StSearchButton = styled.button`
  width: 140px;
  height: 44px;
  border-radius: 4px;
  color: ${theme.colors.bgWhite};
  background: ${theme.colors.brandColor};
  ${theme.font.medium.fontSize20};
`;

export const StAllCountContainer = styled.div`
  margin-bottom: 23px;
  display: flex;
  align-items: center;

  & > span {
    margin-right: 10px;
    ${theme.font.regular.fontSize14};
  }

  & > h4 {
    ${theme.font.medium.fontSize20};
  }
`;

interface BtnContainer {
  position?: string;
  top?: string;
  right?: string;
  left?: string;
  bottom?: string;
  margin?: string;
  justifyContent?: string;
}

export const StBtnContainer = styled.div<BtnContainer>`
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  margin: ${({ margin }) => margin};
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export const StBtn = styled.button`
  width: 200px;
  height: 44px;
  padding: 10px 20px;
  border-radius: 5px;
  color: ${theme.colors.bgWhite};
  background: ${theme.colors.brandColor};
  ${theme.font.medium.fontSize18};
`;

export const StExcelBtn = styled.button`
  width: 149px;
  height: 44px;
  margin-right: 16px;
  border: 1px solid ${theme.colors.black};
  border-radius: 4px;
  color: ${theme.colors.black};
  background: ${theme.colors.bgWhite};
  ${theme.font.medium.fontSize20};
`;

export const StArrowBtn = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: none;
`;

export const StTable = styled.ul`
  width: 1484px;
  min-height: 680px;
  margin-bottom: 43px;
`;

export const StStatusList = styled.ul`
  width: 1480px;
  height: 60px;
  margin-bottom: 21px;
  display: flex;
  align-items: center;
  background: ${theme.colors.bgLight};
`;

interface StatusItem {
  width: string;
  margin: string;
  height?: string;
}

export const StStatusItem = styled.li<StatusItem>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  margin: ${({ margin }) => margin};
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > img {
    width: 12px;
    height: 12px;
  }
`;

export const StStatusItemText = styled.h4`
  color: #acacac;
  ${theme.font.medium.fontSize14};
`;

export const StControllBottom = styled.ul`
  width: 1480px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > li > span {
    ${theme.font.regular.fontSize16};
  }
`;

export const StPaginationContainer = styled.div`
  & > .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > ul {
    list-style: none;
    padding: 0;
  }

  & > ul.pagination li {
    display: inline-block;
    width: 24px;
    height: 24px;
    // border: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }

  & > ul.pagination li:first-child a {
    height: 33px;
    font-size: 30px;
    color: ${theme.colors.grayIcon};
  }

  & > ul.pagination li:last-child a {
    height: 33px;
    font-size: 30px;
    color: ${theme.colors.grayIcon};
  }

  & > ul.pagination li:nth-child(2) a {
    height: 33px;
    font-size: 30px;
    color: ${theme.colors.grayIcon};
  }

  & > ul.pagination li:nth-last-child(2) a {
    height: 33px;
    font-size: 30px;
    color: ${theme.colors.grayIcon};
  }

  & > ul.pagination li a {
    text-decoration: none;
    color: ${theme.colors.grayIcon};
    font-size: 16px;
  }

  & > ul.pagination li.active a {
    color: ${theme.colors.black};
    ${theme.font.bold.fontSize18};
  }

  & > ul.pagination li.active {
    ${theme.font.bold.fontSize18};
  }

  & > ul.pagination li a:hover,
  & > ul.pagination li a.active {
    color: ${theme.colors.black};
  }

  .page-selection {
    width: 48px;
    height: 30px;
    color: #337ab7;
  }
`;

export const StControllSelect = styled.select`
  width: 126px;
  height: 40px;
  padding: 0 9px 0 16px;
  border: 1px solid ${theme.colors.grayLine};
  appearance: none;
  border-radius: 4px;
  background: url("../imgs/controllBox/select_dropdown.png") no-repeat right
    16px center;
`;

// controll

export const StControllContainer = styled.ul`
  margin-bottom: 38px;
  display: flex;
  align-items: center;
`;

export const StPeriodWord = styled.li`
  width: 100px;
  margin-right: 24px;
  color: ${theme.colors.grayFont};
  ${theme.font.medium.fontSize16};
`;

export const StDateContainer = styled.div`
  margin-right: 32px;
  display: flex;
`;

interface ControllItem {
  width?: string;
  height?: string;
  margin?: string;
}

export const StControllItem = styled.li<ControllItem>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
`;

export const StDatePicker = styled(DatePicker)`
  width: 118px;
  height: 40px;
  padding: 9px 16px;
  border: 1px solid ${theme.colors.grayLine};
  border-radius: 4px;
`;

export const StWave = styled.img`
  width: 16px;
  height: 40px;
  margin: 0 18px;
`;

export const StWord = styled.h4`
  ${theme.font.regular.fontSize18};
`;

export const StGraphWord = styled.h4`
  color: ${theme.colors.grayFont};
  ${theme.font.medium.fontSize16};
`;

interface Input {
  width?: string;
  height?: string;
  padding?: string;
  fontSize?: string;
  fontWeight?: string;
}

export const StControllInput = styled.input<Input>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  padding: ${({ padding }) => padding};
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ fontWeight }) => fontWeight};
  border: 1px solid ${theme.colors.grayLine};
  border-radius: 4px;

  &[type="checkbox"] {
    display: none;
  }

  &[type="checkbox"] + label {
    position: relative;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    display: inline-block;
    border: 1px solid ${theme.colors.grayLine};
    border-radius: 11px;
  }

  &[id="check1"]:checked + label::after {
    font-size: ${({ fontSize }) => fontSize}px;
    height: ${({ height }) => height}px;
    position: absolute;
    content: "";
    top: 3px;
    left: 3px;
    width: 14px;
    height: 14px;
    border-radius: 11px;
    background-size: cover;
    background-image: url("/imgs/controllBox/checkbox_active.png");
    background-color: ${theme.colors.bgWhite};
  }
`;

export const StRightSection = styled.ul`
  flex-grow: 1;
  display: flex;
  justify-content: end;
`;

export const StGraphBtnContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-bottom: 24px;
  display: flex;
  justify-content: end;
`;

export const StGraphBtn = styled.button`
  width: 180px;
  height: 44px;
  padding: 9px 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: 1px solid ${theme.colors.black};
  border-radius: 4px;
  ${theme.font.medium.fontSize20};
`;

// graph

// graph table

export const StTableItemContainer = styled.div`
  margin-bottom: 40px;
  display: flex;
  align-items: center;
`;

export const StListContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StServiceName = styled.div`
  width: 200px;
  padding: 19px 16px 21px 16px;
  border: 1px solid;
  ${theme.font.regular.fontSize16};
  flex-shrink: 0;
`;

export const StTalbleItem = styled.div`
  width: 120px;
  padding: 19px 16px 21px 16px;
  border: 1px solid;
  ${theme.font.regular.fontSize16};
  flex-shrink: 0;
`;
