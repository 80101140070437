import axios from "axios";
import refresh from "../auth/refresh/refresh";
import changeDate from "../date/changeDate";

interface Info {
  start: string;
  end: string;
  period: "day" | "week" | "month";
}

const monthCostTotal: any = async (info: Info) => {
  const accessToken = window.sessionStorage.getItem("accessToken");
  const idToken = window.sessionStorage.getItem("idToken");
  try {
    const response = await axios({
      url: "/dashboard/member/total",
      method: "get",
      headers: {
        accept: `application/json`,
        Authorization: `Bearer ${idToken}`,
        "Access-Token": accessToken,
      },
      params: {
        start: changeDate(info.start),
        end: changeDate(info.end),
        period: info.period,
      },
    });

    return response.data;
  } catch (error: any) {
    const statusCode = error.response.data.statusCode;
    if (statusCode === 401) {
      await refresh();
      return monthCostTotal(info);
    } else {
      return error.response.data;
    }
  }
};

export default monthCostTotal;
