import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { alertAction } from "../../redux/features/modals/alertModal";
import { useAppDispatch, useAppSelector } from "../../redux/store/store";
import logout from "../../services/auth/logout/logout";
import { theme } from "../../styles/theme";

const StNavigation = styled.nav`
  min-width: 280px;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: visibility 3ms linear;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.08);
  background: ${theme.colors.bgWhite};
`;

const StUserInfo = styled.ul`
  height: 124px;
  margin-bottom: 16px;
  background: ${theme.colors.bgLight};

  & > :nth-child(1) {
    padding: 22px 40px;
    display: flex;
    border-bottom: 1px solid ${theme.colors.grayLine};

    & > img {
      width: 36px;
      height: 36px;
      margin-right: 12px;
    }

    & > div {
      display: flex;
      align-items: center;

      & > p {
        ${theme.font.medium.fontSize14};
      }
    }
  }

  & > :nth-child(2) {
    padding: 12px 0;
    display: flex;

    & > div {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      & > button {
        background: none;
        ${theme.font.bold.fontSize14};
      }
    }
  }
`;

const StMenuItem = styled.li`
  height: 60px;
  padding: 18px 40px 20px 40px;

  & > button {
    color: ${theme.colors.secondaryBtn};
    background: none;
    ${theme.font.bold.fontSize16};
  }
`;

const StLinkBtn = styled.p`
  color: ${theme.colors.secondaryBtn};
  background: none;
  line-height: 22px;
  font-size: 16px;
  letter-spacing: -0.02rem;
`;

const StDetail = styled.details`
  height: 60px;
  padding: 18px 40px 20px 40px;
  display: flex;
  color: ${theme.colors.secondaryBtn};
  border-bottom: 1px solid ${theme.colors.grayLine};
  ${theme.font.bold.fontSize16};

  & > summary {
    cursor: pointer;
    // list-style: none;
  }

  & > summary::-webkit-details-marker {
    display: none;
  }

  & > button {
    height: 60px;
    padding: 18px 40px 20px 40px;
    color: ${theme.colors.secondaryBtn};
    background: none;
    ${theme.font.medium.fontSize16};
  }
`;

interface Props {
  clickMenu: boolean;
}

const Navigation: React.FC<Props> = ({ clickMenu }) => {
  const naviagate = useNavigate();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.userInfo.data);
  const [menuToggle, setMenuToggle] = useState(false);

  const naviList = [
    "Dashboard",
    "통합 사용량",
    "API 사용량",
    "Invoice",
    "문의하기",
    "공지사항",
  ];

  const goPath = (path: string) => {
    naviagate(path);
  };

  const onLink = (name: string) => {
    switch (name) {
      case "Dashboard":
        return "/dashboard";

      case "통합 사용량":
        return "/use/all";

      case "API 사용량":
        return "/use/api";

      case "공지사항":
        return "/notice";

      case "문의하기":
        return "/contact";

      case "Invoice":
        return "/invoice";

      case "고객사 정보":
        return "/info/client";

      case "서비스 정보":
        return "/info/service";

      default:
        return "/";
    }
  };

  const onLogout = async () => {
    const { statusCode, data, message } = await logout();

    if (statusCode === 200) {
      // dispatch(alertAction.openModal({ modalName: "alert", text: message }));
      return;
    } else {
      dispatch(alertAction.openModal({ modalName: "alert", text: message }));
    }
  };

  const onMenuToggle = () => {
    setMenuToggle(!menuToggle);
  };

  return (
    <StNavigation
      style={{
        display: clickMenu ? "none" : "flex",
      }}
    >
      <StUserInfo>
        <li>
          <img src="/imgs/navigation/navi_logo.png" alt="navi_logo" />
          <div>
            <p>{userInfo.name}님 환영합니다.</p>
          </div>
        </li>

        <li>
          <div>
            <button onClick={() => goPath("/user/mypage")}>마이페이지</button>
          </div>
          <div>
            <button onClick={onLogout}>로그아웃</button>
          </div>
        </li>
      </StUserInfo>

      <ul>
        {naviList.map((list) => {
          return (
            <StMenuItem key={list}>
              <NavLink
                key={list}
                to={onLink(list)}
                style={({ isActive }) => ({
                  fontWeight: isActive ? "bold" : "normal",
                  color: isActive
                    ? `${theme.colors.brandColor}`
                    : `${theme.colors.black}`,
                  background: "none",
                  lineHeight: "22px",
                  fontSize: "16px",
                })}
              >
                {list}
              </NavLink>
            </StMenuItem>
          );
        })}
      </ul>

      <StDetail>
        <summary onClick={onMenuToggle}>정보</summary>

        <div
          style={{
            height: "60px",
            padding: "28px 40px 20px 40px",
          }}
        >
          <NavLink
            key={`client`}
            to={onLink("고객사 정보")}
            style={({ isActive }) => ({
              fontWeight: isActive ? "bold" : "normal",
              color: isActive
                ? `${theme.colors.brandColor}`
                : `${theme.colors.black}`,
            })}
          >
            고객사 정보
          </NavLink>
        </div>

        <div
          style={{
            height: "60px",
            padding: "18px 40px 20px 40px",
          }}
        >
          <NavLink
            key={`client`}
            to={onLink("서비스 정보")}
            style={({ isActive }) => ({
              fontWeight: isActive ? "bold" : "normal",
              color: isActive
                ? `${theme.colors.brandColor}`
                : `${theme.colors.black}`,
            })}
          >
            서비스 정보
          </NavLink>
        </div>
      </StDetail>

      <div
        style={{
          width: menuToggle ? "280px" : 0,
          position: "absolute",
          top: "710px",
          left: "0px",
          borderBottom: `1px solid ${theme.colors.grayLine}`,
        }}
      ></div>
    </StNavigation>
  );
};

export default Navigation;
