import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  StBtn,
  StBtnContainer,
  StPageTitle,
} from "../../components/CommonStyled/ComonStyled";
import ContainerLayout from "../../components/Layout/ContainerLayout/ContainerLayout";
import { alertAction } from "../../redux/features/modals/alertModal";
import { useAppDispatch, useAppSelector } from "../../redux/store/store";
import contactAnswer from "../../services/contact/contactAnswer";
import { theme } from "../../styles/theme";

const StContactAnswerContainer = styled.div`
  width: 1560px;
  height: 100%;
  padding: 40px 40px 0 40px;
  border-radius: 12px;
  background: ${theme.colors.bgWhite};
`;

const StSubTitle = styled.h3`
  margin-bottom: 24px;
  ${theme.font.bold.fontSize18};
`;

const StUserInfoList = styled.ul`
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid ${theme.colors.grayLine};
`;

const StUserInfoItem = styled.li`
  width: 50%;
  margin-bottom: 40px;
  display: flex;

  & > label {
    width: 105px;
    height: 22px;
    margin-right: 40px;
    color: #777777;
    ${theme.font.medium.fontSize16};
  }

  & > p {
    width: 1232px;
    ${theme.font.regular.fontSize16};
  }
`;

const StQuestionList = styled.ul`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${theme.colors.grayLine};
`;

const StQuestionItem = styled.li`
  width: 100%;
  margin-bottom: 40px;
  display: flex;

  & > label {
    width: 80px;
    height: 22px;
    margin-right: 40px;
    color: #777777;
    ${theme.font.medium.fontSize16};
  }

  & > p {
    width: 1232px;
    ${theme.font.regular.fontSize16};
  }
`;

const StAnswerList = styled.ul`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
`;

const StAnswerItem = styled.li`
  width: 100%;
  margin-bottom: 40px;
  display: flex;

  & > label {
    width: 80px;
    height: 22px;
    margin-right: 40px;
    color: #777777;
    ${theme.font.medium.fontSize16};
  }

  & > p {
    width: 1232px;
    ${theme.font.regular.fontSize16};
  }
`;

type Answer = {
  answerContent: string;
  answerId: string;
  answerName: string;
  answerNote: string;
  authorDepartment: string;
  authorId: string;
  authorName: string;
  authorPhone: string;
  content: string;
  postId: string;
  spCorpName: string;
  spServiceName: string;
  title: string;
};

const ContactAnswer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const postId = useLocation().state.postId;
  const userInfo = useAppSelector((state) => state.userInfo.data);
  const [answer, setAnswer] = useState<Answer>();

  const userInfoList = [
    { text: "회사명", value: userInfo.corpName },
    { text: "서비스명", value: answer?.spServiceName },
    { text: "이름", value: userInfo.name },
    { text: "아이디", value: userInfo.id },
    { text: "전화번호", value: userInfo.phoneNumber },
    { text: "부서", value: userInfo.department },
  ];

  const questionList = [
    { text: "문의 제목", value: answer?.title },
    { text: "문의 내용", value: answer?.content },
  ];

  const answerList = [
    { text: "답변자", value: answer?.answerName },
    { text: "답변 내용", value: answer?.answerContent },
    { text: "비고", value: answer?.answerNote },
  ];

  const getAnswer = async () => {
    const { statusCode, data, message } = await contactAnswer(postId);

    if (statusCode === 200) {
      setAnswer(data);
    } else {
      dispatch(alertAction.openModal({ modalName: "alert", text: message }));
    }
  };

  useEffect(() => {
    getAnswer();
  }, []);

  return (
    <ContainerLayout>
      <StPageTitle>문의하기</StPageTitle>

      <StContactAnswerContainer>
        <StSubTitle>답변 확인하기</StSubTitle>

        <StUserInfoList>
          {userInfoList.map((item, index) => {
            return (
              <StUserInfoItem key={index}>
                <label htmlFor="">{item.text}</label>
                <p>{item.value}</p>
              </StUserInfoItem>
            );
          })}
        </StUserInfoList>
        <StQuestionList>
          {questionList.map((item, index) => {
            return (
              <StQuestionItem key={index}>
                <label htmlFor="">{item.text}</label>
                <p>{item.value}</p>
              </StQuestionItem>
            );
          })}
        </StQuestionList>
        <StAnswerList>
          {answerList.map((item, index) => {
            return (
              <StAnswerItem key={index}>
                <label htmlFor="">{item.text}</label>
                <p>{item.value}</p>
              </StAnswerItem>
            );
          })}
        </StAnswerList>
        <StBtnContainer justifyContent="end">
          <StBtn onClick={() => navigate("/contact")}>확인</StBtn>
        </StBtnContainer>
      </StContactAnswerContainer>
    </ContainerLayout>
  );
};

export default ContactAnswer;
