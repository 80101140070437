import React, { useEffect, useState } from "react";
import {
  StAllCountContainer,
  StControllBottom,
  StControllContainer,
  StControllInput,
  StControllItem,
  StControllSelect,
  StDateContainer,
  StDatePicker,
  StPageTitle,
  StPaginationContainer,
  StPeriodWord,
  StRightSection,
  StSearchButton,
  StWave,
  StWord,
} from "../../components/CommonStyled/ComonStyled";
import ContainerLayout from "../../components/Layout/ContainerLayout/ContainerLayout";
import { alertAction } from "../../redux/features/modals/alertModal";
import { useAppDispatch, useAppSelector } from "../../redux/store/store";
import downloadExcel from "../../services/downloadExcel/downloadExcel";
import invoice from "../../services/invoice/invoice";
import { theme } from "../../styles/theme";
import styled from "styled-components";
import EmptyList from "../../components/EmptyList/EmptyList";
import Pagination from "react-js-pagination";
import { changeCost } from "../../services/common/changeCost";
import invoiceCost from "../../services/invoice/invoiceCost";
import changeDate from "../../services/date/changeDate";
import changeDateMonth from "../../services/date/chageDateMonth";

type InvoiceList = {
  id: number;
  invoiceName: string;
  releaseDate: string;
  cost: number;
  stdDt: string;
};

type Page = {
  currentPage: number;
  totalCost?: number;
  totalCount: number;
  totalPages: number;
  pageLimit?: number;
};

type InvoiceData = {
  currentPage: number;
  invoiceList: InvoiceList[];
  pageLimit: number;
  totalCost: number;
  totalCount: number;
  totalPages: number;
};

type Info = {
  start?: string;
  end?: string;
  spServiceName: string;
  page: number;
  pageLimit: number;
  sort?: "id" | "invoice_name" | "std_dt" | "cost" | "no";
  sort_order?: "asc" | "desc";
};

const StInvoiceContainer = styled.div`
  width: 1560px;
  height: 100%;
  padding: 22px 24px 36px 40px;
  border-radius: 16px;
  background: ${theme.colors.bgWhite};
`;

const StTable = styled.ul`
  width: 1484px;
  min-height: 680px;
  margin-bottom: 43px;
`;

const StStatusList = styled.ul`
  width: 1480px;
  height: 60px;
  margin-bottom: 21px;
  display: flex;
  align-items: center;
  background: ${theme.colors.bgLight};
`;

interface StatusItem {
  width: string;
  margin: string;
  height?: string;
}

const StStatusItem = styled.li<StatusItem>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  margin: ${({ margin }) => margin};
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > img {
    width: 12px;
    height: 12px;
  }
`;

const StStatusItemText = styled.h4`
  color: #acacac;
  ${theme.font.medium.fontSize14};
`;

const StTotalCost = styled.ul`
  padding: 19px 16px;
  display: flex;
  border-top: 1px solid ${theme.colors.grayLine};
  border-bottom: 1px solid ${theme.colors.grayLine};
  ${theme.font.bold.fontSize16};

  & > :first-child {
    color: ${theme.colors.grayIcon};
  }

  & > :last-child {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
`;

interface Props {
  serviceList: any;
}

const Invoice: React.FC<Props> = ({ serviceList }) => {
  const prevMonth = new Date(new Date().setDate(new Date().getMonth() - 1));
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<any>(prevMonth);
  const [endDate, setEndDate] = useState<any>(new Date());
  const [info, setInfo] = useState<Info>({
    start: changeDateMonth(startDate).slice(0, 6),
    end: changeDateMonth(endDate).slice(0, 6),
    page: 1,
    spServiceName: serviceList[0],
    pageLimit: 10,
  });
  const [page, setPage] = useState<Page>({
    totalCount: 1,
    currentPage: 1,
    totalPages: 1,
  });
  const [typeList, setTypeList] = useState({
    name: [
      {
        name: "No.",
        width: "56",
        height: "20",
        margin: "0 88px 0 16px",
        sort: "id",
      },
      {
        name: "내역서명",
        width: "256",
        height: "20",
        margin: "0 88px 0 0",
        sort: "invoice_name",
      },
      {
        name: "발행일시",
        width: "256",
        height: "20",
        margin: "0 88px 0 0",
        sort: "std_dt",
      },
      {
        name: "사용금액",
        width: "256",
        height: "20",
        margin: "0 88px 0 0",
        sort: "cost",
      },
      {
        name: "이용내역서",
        width: "256",
        height: "20",
        margin: "0 32px 0 0",
        sort: "no",
      },
    ],
    isOrder: "asc",
  });

  const [list, setList] = useState<InvoiceList[]>();

  const getInvoice = async () => {
    const { statusCode, data, message } = await invoice(info);
    const { invoiceList, ...others } = await data;
    if (statusCode === 200) {
      setList(invoiceList);
      setPage(others);
    } else {
      dispatch(alertAction.openModal({ modalName: "alert", text: message }));
    }
  };

  const onChangeDate = (type: string, date: any) => {
    if (type === "start") {
      setStartDate(date);
      setInfo({ ...info, [type]: changeDateMonth(date).slice(0, 6) });
    } else {
      setEndDate(date);
      setInfo({ ...info, [type]: changeDateMonth(date).slice(0, 6) });
    }
  };

  const onSort = (text: "id" | "invoice_name" | "std_dt" | "cost" | "no") => {
    if (text === "no") {
      return;
    } else {
      if (typeList.isOrder === "asc") {
        setTypeList({ ...typeList, isOrder: "desc" });
        setInfo({ ...info, sort: text, sort_order: "desc" });
      } else {
        setTypeList({ ...typeList, isOrder: "asc" });
        setInfo({ ...info, sort: text, sort_order: "asc" });
      }
    }
  };

  const onItem = async (info: { stdDt: string; spServiceName: string }) => {
    const { statusCode, data, message } = await invoiceCost(info);
    if (statusCode === 200) {
      window.open(data.downloadLink);
    } else {
      dispatch(alertAction.openModal({ modalName: "alert", text: message }));
    }
  };

  const onType = (
    event:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    const name = event.target.name;

    if (name === "pageLimit") {
      setInfo({ ...info, [name]: Number(value) });
      setPage({ ...page, currentPage: 1 });
    } else {
      setInfo({ ...info, [name]: value });
    }
  };

  const resultList = list?.map((item: InvoiceList) => {
    const { id, invoiceName, releaseDate, cost, stdDt } = item;

    const resultArr = [
      { name: id, width: "56", height: "20", margin: "0 88px 0 16px" },
      { name: invoiceName, width: "256", height: "20", margin: "0 88px 0 0" },
      { name: releaseDate, width: "256", height: "20", margin: "0 88px 0 0" },
      {
        name: changeCost(cost),
        width: "256",
        height: "20",
        margin: "0 88px 0 0",
      },
      {
        name: invoiceName,
        width: "256",
        height: "20",
        margin: "0 32px 0 0",
        type: { stdDt: stdDt, spServiceName: info.spServiceName },
      },
    ];

    return resultArr;
  });

  const handlePageChange = (clickedNumber: number) => {
    setPage({ ...page, currentPage: clickedNumber });
  };

  const onServiceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    const name = event.target.name;
    setInfo({ ...info, [name]: value });
  };

  useEffect(() => {
    getInvoice();
  }, [info.spServiceName, info.pageLimit, info.sort_order, serviceList]);

  return (
    <ContainerLayout>
      <StPageTitle>Invoice</StPageTitle>

      <StInvoiceContainer>
        <StControllContainer>
          <StPeriodWord>조회기간</StPeriodWord>

          <StDateContainer>
            <StDatePicker
              dateFormat="yyyy-MM"
              showMonthYearPicker
              showFullMonthYearPicker
              selected={startDate}
              onChange={(date) => onChangeDate("start", date)}
              startDate={startDate}
              endDate={endDate}
            />
            <StWave src="/imgs/calendar/~.png" alt="~" />
            <StDatePicker
              dateFormat="yyyy-MM"
              showMonthYearPicker
              showFullMonthYearPicker
              selected={endDate}
              onChange={(date) => onChangeDate("end", date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
          </StDateContainer>

          <StRightSection>
            <StControllItem margin="0 16px 0 0">
              <StControllSelect
                style={{ width: "240px", height: "40px" }}
                name="spServiceName"
                onChange={(event) => onServiceChange(event)}
              >
                {serviceList.map((item: string, index: number) => {
                  return (
                    <option key={index} value={`${item}`}>
                      {item}
                    </option>
                  );
                })}
              </StControllSelect>
            </StControllItem>
            <li>
              <StSearchButton onClick={getInvoice}>검색</StSearchButton>
            </li>
          </StRightSection>
        </StControllContainer>

        <StAllCountContainer>
          <span>전체</span>
          <h4>{page?.totalCount}건</h4>
        </StAllCountContainer>

        <StTable>
          {resultList?.length === 0 ? (
            <EmptyList />
          ) : (
            <>
              <StStatusList>
                {typeList.name.map((item, index) => {
                  return (
                    <StStatusItem
                      key={`${item.name} + ${index} + ${Math.random()}`}
                      width={item.width}
                      margin={item.margin}
                      height={item.height}
                      onClick={() =>
                        onSort(
                          item.sort as
                            | "id"
                            | "invoice_name"
                            | "std_dt"
                            | "cost"
                            | "no"
                        )
                      }
                    >
                      <StStatusItemText>{item.name}</StStatusItemText>
                      {item.sort !== "no" ? (
                        <img src="/imgs/table/status_down.png" alt="down" />
                      ) : (
                        ""
                      )}
                    </StStatusItem>
                  );
                })}
              </StStatusList>

              {resultList?.map((item, index) => {
                const result = item.map((item) => {
                  return (
                    <StStatusItem
                      key={`${item.name} + ${index} + ${Math.random()}`}
                      width={item.width}
                      margin={item.margin}
                      height={item.height}
                      onClick={
                        item.type
                          ? (event) => onItem(item.type)
                          : () => {
                              return;
                            }
                      }
                    >
                      <StStatusItemText
                        style={{
                          color: item.type ? `${theme.colors.brandColor}` : "",
                          borderBottom: item.type
                            ? `1px solid ${theme.colors.brandColor}`
                            : "",
                        }}
                      >
                        {item.name}
                      </StStatusItemText>
                    </StStatusItem>
                  );
                });
                return (
                  <ul
                    key={index}
                    style={{
                      display: "flex",
                      height: "60px",
                      alignItems: "center",
                    }}
                  >
                    {result}
                  </ul>
                );
              })}
              <StTotalCost>
                <li>총 사용금액</li>
                <li>
                  <div>
                    {page.totalCost ? changeCost(Number(page.totalCost)) : ""}
                  </div>
                </li>
              </StTotalCost>
            </>
          )}
        </StTable>

        <StControllBottom
          style={{ display: resultList?.length === 0 ? "none" : "flex" }}
        >
          <li style={{ flexGrow: 1 }}>
            <StPaginationContainer>
              <Pagination
                activePage={page.currentPage}
                itemsCountPerPage={info.pageLimit}
                totalItemsCount={page.totalPages}
                pageRangeDisplayed={5}
                prevPageText={"‹"}
                nextPageText={"›"}
                onChange={handlePageChange}
              />
            </StPaginationContainer>
          </li>

          <li>
            <StControllSelect
              name="pageLimit"
              defaultValue={10}
              onChange={(event) => onType(event)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </StControllSelect>
            <span style={{ marginLeft: "8px" }}>개씩 보기</span>
          </li>
        </StControllBottom>
      </StInvoiceContainer>
    </ContainerLayout>
  );
};

export default Invoice;
