import React from "react";
import styled from "styled-components";
import { theme } from "../../styles/theme";

const StFooter = styled.footer`
  width: 100%;
  height: 123px;
  padding: 23px 0 24px 40px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${theme.colors.grayLine};
  background: ${theme.colors.bgDark};
`;

const StService = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  & > button {
    margin-right: 12px;
    color: ${theme.colors.grayIcon};
    background: none;
    ${theme.font.bold.fontSize14};
  }
`;

const StLogo = styled.img`
  width: 243px;
  height: 26px;
  margin-right: 32px;
`;

const StAdress = styled.address`
  color: ${theme.colors.grayIcon};
  ${theme.font.regular.fontSize14};
`;

const Footer = () => {
  const onService = (path: string) => {
    if (path === "service") {
      window.open(
        "https://tunib-ai.notion.site/e578d40cc942410fa18ef4ab9da7b3e7"
      );
    } else {
      window.open(
        "https://tunib-ai.notion.site/139837c161c54449aa4a04db499436e1"
      );
    }
  };

  return (
    <StFooter>
      <StService>
        <StLogo src="/imgs/header/header_logo.png" alt="logo" />
        <button onClick={() => onService("service")}>Terms of Service</button>
        <button onClick={() => onService("privacy")}>Privacy Policy</button>
      </StService>

      <StAdress>
        <p>
          TUNiB Inc. | ADDRESS : (13488) 20, Pangyo-ro 289beon-gil, Bundang-gu,
          Seongnam-si, Gyeonggi-do, Republic of Korea | NAME OF REPRESENTATIVE :
          PARK KYUBYONG
        </p>
        <p>©COPYRIGHT 2022 TUNiB. All Rights Reserved.</p>
      </StAdress>
    </StFooter>
  );
};

export default Footer;
