import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { StPageTitle } from "../../components/CommonStyled/ComonStyled";
import ContainerLayout from "../../components/Layout/ContainerLayout/ContainerLayout";
import { alertAction } from "../../redux/features/modals/alertModal";
import { useAppDispatch } from "../../redux/store/store";
import dashboardTop from "../../services/dashboard/dashboardTop";

import serviceList from "../../services/user/serviceList";
import { theme } from "../../styles/theme";

import { changeCost, numberTo } from "../../services/common/changeCost";

import ServiceUseGraph from "../../components/Graph/ServiceUseGraph";
import MonthTotalGraph from "../../components/Graph/MonthCostTotalGraph";
import MonthServiceTotalGraph from "../../components/Graph/MonthServiceTotalGraph";
import MonthTotalCostGraph from "../../components/Graph/MonthTotalCostGraph";

const StTitleContainer = styled.div`
  width: 1560px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;

  & > select {
    width: 160px;
    padding: 9px 16px;
    height: 40px;
    border: 1px solid ${theme.colors.grayLine};
    appearance: none;
    border-radius: 4px;

    background: url("../imgs/controllBox/select_dropdown.png") no-repeat right
      16px center;
    background-color: ${theme.colors.bgWhite};
  }
`;

const StCardContainer = styled.ul`
  position: relative;
  display: flex;
`;

const StCard = styled.li`
  width: 215px;
  height: 140px;
  margin-right: 8px;
  padding: 16px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background: ${theme.colors.bgWhite};
  box-shadow: 4px 4px 10px rgba(159, 171, 189, 0.15);
  border-radius: 8px;
  overflow: scroll;
`;

const StCardLabel = styled.h4`
  margin-bottom: 4;
  ${theme.font.bold.fontSize16};
`;

const StCardDate = styled.h4`
  margin-bottom: 24px;
  color: #acacac;
  ${theme.font.regular.fontSize14};
`;

const StCardValue = styled.h4`
  width: 100%;
  text-align: end;
  ${theme.font.medium.fontSize32};
`;

const StGraphContainer = styled.div`
  width: 1560px;
  height: 530px;
  margin-top: 40px;
  padding: 22px 24px 0 40px;
  border-radius: 16px;
  background: ${theme.colors.bgWhite};
`;

const StGraphTitle = styled.h2`
  margin-bottom: 16px;
  color: ${theme.colors.brandColor};
  ${theme.font.medium.fontSize20};
`;

const StGraph = styled.div`
  width: 1496px;
  height: 300px;
  border-radius: 0px;
  overflow: scroll;
`;

const StHoverPar = styled.p`
  position: absolute;
  width: 160px;
  height: 72px;
  padding: 6px 16px;
  top: 120px;
  left: 50px;
  background: ${theme.colors.brandColor};
  border-radius: 6px;
  color: ${theme.colors.bgWhite};
  ${theme.font.regular.fontSize14};
  overflow: auto;
`;
interface DataList {
  key: string;
  value: string;
}

type GraphDataDay = {
  stdDt: string;
  value: number;
};

type GraphDataWeek = {
  week: string;
  stats: {
    stdDt: string;
    days: {
      stdDt: string;
      value: number;
    }[];
  };
};

const AllUse = () => {
  return (
    <ContainerLayout>
      <StPageTitle>통합 사용량</StPageTitle>

      <MonthTotalGraph />
      <MonthTotalCostGraph />
      <ServiceUseGraph />
      <MonthServiceTotalGraph />
    </ContainerLayout>
  );
};

export default AllUse;
