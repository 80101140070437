import axios, { AxiosPromise } from "axios";
import refresh from "../auth/refresh/refresh";
import changeDateMonth from "../date/chageDateMonth";

interface Info {
  start: string;
  end: string;
}

interface DownloadExcel {
  (info: Info): AxiosPromise;
}

const monthServiceCostAll: any = async (info: Info) => {
  const accessToken = window.sessionStorage.getItem("accessToken");
  const idToken = window.sessionStorage.getItem("idToken");

  try {
    const response = await axios({
      url: "/dashboard/member/billing/sp-service/excel",
      method: "get",
      headers: {
        Accept: `application/json`,
        Authorization: `Bearer ${idToken}`,
        "Access-Token": accessToken,
      },
      params: {
        start: changeDateMonth(info.start).slice(0, 6),
        end: changeDateMonth(info.end).slice(0, 6),
      },
    });
    return response.data;
  } catch (error: any) {
    const statusCode = error.response.data.statusCode;
    if (statusCode === 401) {
      await refresh();
      return monthServiceCostAll(info);
    } else {
      return error.response.data;
    }
  }
};

export default monthServiceCostAll;
