import React, { useEffect, useState } from "react";
import {
  StAllCountContainer,
  StControllBottom,
  StControllInput,
  StPageTitle,
  StPaginationContainer,
  StRightSection,
  StSearchButton,
  StStatusItem,
  StStatusItemText,
  StStatusList,
  StTable,
} from "../../components/CommonStyled/ComonStyled";
import ContainerLayout from "../../components/Layout/ContainerLayout/ContainerLayout";
import { theme } from "../../styles/theme";
import styled from "styled-components";
import noticeList from "../../services/notice/noticeList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAppDispatch } from "../../redux/store/store";
import { alertAction } from "../../redux/features/modals/alertModal";
import EmptyList from "../../components/EmptyList/EmptyList";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import changeDate from "../../services/date/changeDate";

const StNoticeContainer = styled.div`
  width: 1560px;
  height: 100%;
  padding: 22px 24px 36px 40px;
  border-radius: 16px;
  background: ${theme.colors.bgWhite};
`;

const StControllContainer = styled.ul`
  margin-bottom: 38px;
  display: flex;
  align-items: center;
`;

const StPeriodWord = styled.li`
  width: 100px;
  margin-right: 24px;
  ${theme.font.medium.fontSize16};
`;

const StDateContainer = styled.div`
  margin-right: 32px;
  display: flex;
`;

interface ControllItem {
  width?: string;
  height?: string;
  margin?: string;
}

const StControllItem = styled.li<ControllItem>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
`;

const StDatePicker = styled(DatePicker)`
  width: 118px;
  height: 40px;
  padding: 9px 16px;
  border: 1px solid ${theme.colors.grayLine};
  border-radius: 4px;
`;

const StWave = styled.img`
  width: 16px;
  height: 40px;
  margin: 0 18px;
`;

const StWord = styled.h4`
  ${theme.font.regular.fontSize18};
`;

interface Input {
  width?: string;
  height?: string;
  padding?: string;
  fontSize?: string;
  fontWeight?: string;
}

const StControllSelect = styled.select`
  width: 94px;
  height: 40px;
  padding: 0 9px 0 16px;
  border: 1px solid ${theme.colors.grayLine};
  appearance: none;
  border-radius: 4px;
  background: url("../imgs/controllBox/select_dropdown.png") no-repeat right
    16px center;
`;

interface Info {
  page: number;
  item?: "title" | "admin_id";
  search?: string;
  sort_order?: "asc" | "desc";
  sort?: string;
  page_limit?: number;
  start?: string;
  end?: string;
}

type Page = {
  currentPage: number;
  totalCount: number;
  totalPage: number;
  pageLimit?: number;
};

const Notice = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<any>(
    new Date(new Date(new Date().setDate(new Date().getDate() - 90)))
  );
  const [endDate, setEndDate] = useState<any>(new Date());
  const [info, setInfo] = useState<Info>({
    page: 1,
    start: startDate,
    end: endDate,
  });
  const [typeList, setTypeList] = useState({
    name: [
      {
        name: "No.",
        width: "56",
        height: "20",
        margin: "0 104px 0 16px",
        sort: "idx",
      },
      {
        name: "제목",
        width: "240",
        height: "20",
        margin: "0 104px 0 0",
        sort: "title",
      },
      {
        name: "작성일시",
        width: "176",
        height: "20",
        margin: "0 104px 0 0",
        sort: "insert_dttm",
      },
      {
        name: "수정일시",
        width: "176",
        height: "20",
        margin: "0 104px 0 0",
        sort: "update_dttm",
      },
      {
        name: "작성자",
        width: "116",
        height: "20",
        margin: "0 104px 0 0",
        sort: "admin_id",
      },
      {
        name: "조회수",
        width: "256",
        height: "20",
        margin: "0 84px 0 0",
        sort: "views",
      },
    ],
    isOrder: "asc",
  });
  const [list, setList] = useState([]);
  const [page, setPage] = useState<Page>({
    totalCount: 1,
    currentPage: 1,
    totalPage: 1,
  });

  const getNoticeList = async () => {
    const { statusCode, data, message } = await noticeList(
      info,
      page.currentPage
    );

    if (statusCode === 200) {
      const { noticeList, ...others } = await data;
      setList(noticeList);
      setPage({ ...page, ...others });
    } else {
      dispatch(alertAction.openModal({ modalName: "alert", text: message }));
    }
  };

  const handlePageChange = (clickedNumber: number) => {
    setPage({ ...page, currentPage: clickedNumber });
  };

  const onSort = (text: string) => {
    if (text === "no") {
      return;
    } else {
      if (typeList.isOrder === "asc") {
        setTypeList({ ...typeList, isOrder: "desc" });
        setInfo({ ...info, sort: text, sort_order: "desc" });
      } else {
        setTypeList({ ...typeList, isOrder: "asc" });
        setInfo({ ...info, sort: text, sort_order: "asc" });
      }
    }
  };

  const onType = (
    event:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    const name = event.target.name;

    if (name === "page_limit") {
      setInfo({ ...info, [name]: Number(value) });
      setPage({ ...page, currentPage: 1 });
    } else if (value === "all") {
      delete info.item;
    } else {
      setInfo({ ...info, [name]: value });
    }
  };

  const onChangeDate = (type: string, date: any) => {
    if (type === "start") {
      setStartDate(date);
      setInfo({ ...info, [type]: date });
    } else {
      setEndDate(date);
      setInfo({ ...info, [type]: date });
    }
  };

  const resultList = list.map((item) => {
    const { idx, title, updateDttm, insertDttm, adminId, views } = item;

    const resultArr = [
      { name: idx, width: "56", height: "20", margin: "0 104px 0 16px" },
      {
        name: title,
        width: "240",
        height: "20",
        margin: "0 104px 0 0",
        type: { postId: idx },
      },
      { name: insertDttm, width: "176", height: "20", margin: "0 104px 0 0" },
      { name: updateDttm, width: "176", height: "20", margin: "0 104px 0 0" },
      { name: adminId, width: "116", height: "20", margin: "0 104px 0 0" },
      { name: views, width: "256", height: "20", margin: "0 84px 0 0" },
    ];
    return resultArr;
  });

  const onPath = (item: any) => {
    if (item.type) {
      const postId = item.type.postId;
      const path = `/notice/${postId}`;
      navigate(path, { state: { postId: postId } });
    } else {
      return;
    }
  };

  useEffect(() => {
    getNoticeList();
  }, [info.page_limit, info.sort_order, page.currentPage]);

  return (
    <ContainerLayout>
      <StPageTitle>공지사항</StPageTitle>

      <StNoticeContainer>
        <StControllContainer>
          <StPeriodWord>조회기간</StPeriodWord>
          <StDateContainer>
            <StDatePicker
              dateFormat="yyyy-MM-dd"
              selected={startDate}
              onChange={(date) => onChangeDate("start", date)}
              startDate={startDate}
              endDate={endDate}
            />
            <StWave src="/imgs/calendar/~.png" alt="~" />
            <StDatePicker
              dateFormat="yyyy-MM-dd"
              selected={endDate}
              onChange={(date) => onChangeDate("end", date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
          </StDateContainer>

          <StRightSection>
            <StControllItem margin="0 16px 0 0">
              <StControllSelect name="item" onChange={(event) => onType(event)}>
                <option value="all">전체</option>
                <option value="title">제목</option>
                <option value="admin_id">작성자</option>
              </StControllSelect>
            </StControllItem>
            <StControllItem margin="0 24px 0 0">
              <StControllInput
                onChange={(event) => onType(event)}
                name="search"
                width="368"
                height="40"
                padding="9px 16px"
                fontSize="16"
                fontWeight="400"
                type="text"
                placeholder="검색어를 입력해주세요."
              />
            </StControllItem>
            <li>
              <StSearchButton onClick={getNoticeList}>검색</StSearchButton>
            </li>
          </StRightSection>
        </StControllContainer>
        <StAllCountContainer>
          <span>전체</span>
          <h4>{page.totalCount}</h4>
        </StAllCountContainer>
        <StTable>
          {resultList?.length === 0 ? (
            <EmptyList />
          ) : (
            <>
              <StStatusList>
                {typeList.name.map((item, index) => {
                  return (
                    <StStatusItem
                      key={`${item.name} + ${index} + ${Math.random()}`}
                      width={item.width}
                      margin={item.margin}
                      height={item.height}
                      onClick={() => onSort(item.sort)}
                    >
                      <StStatusItemText>{item.name}</StStatusItemText>
                      <img src="/imgs/table/status_down.png" alt="down" />
                    </StStatusItem>
                  );
                })}
              </StStatusList>

              {resultList?.map((item, index) => {
                const result = item.map((item) => {
                  return (
                    <StStatusItem
                      key={`${item.name} + ${index} + ${Math.random()}`}
                      width={item.width}
                      margin={item.margin}
                      height={item.height}
                      onClick={() => onPath(item)}
                    >
                      {item.type?.postId ? (
                        <StStatusItemText
                          style={{
                            cursor: "pointer",
                            borderBottom: "1px solid",
                          }}
                        >
                          {item.name}
                        </StStatusItemText>
                      ) : (
                        <StStatusItemText>{item.name}</StStatusItemText>
                      )}
                    </StStatusItem>
                  );
                });
                return (
                  <ul
                    key={Math.random()}
                    style={{
                      display: "flex",
                      height: "60px",
                      alignItems: "center",
                    }}
                  >
                    {result}
                  </ul>
                );
              })}
            </>
          )}
        </StTable>

        <StControllBottom
          style={{ display: resultList?.length === 0 ? "none" : "flex" }}
        >
          <li style={{ flexGrow: 1 }}>
            <StPaginationContainer>
              <Pagination
                activePage={page.currentPage}
                itemsCountPerPage={page.pageLimit}
                totalItemsCount={page.totalCount}
                pageRangeDisplayed={5}
                prevPageText={"‹"}
                nextPageText={"›"}
                onChange={handlePageChange}
              />
            </StPaginationContainer>
          </li>

          <li>
            <StControllSelect
              name="page_limit"
              defaultValue={10}
              onChange={(event) => onType(event)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </StControllSelect>
            <span style={{ marginLeft: "8px" }}>개씩 보기</span>
          </li>
        </StControllBottom>
      </StNoticeContainer>
    </ContainerLayout>
  );
};

export default Notice;
