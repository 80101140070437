import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../../redux/store/store";
import { theme } from "../../styles/theme";

const StHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  background: ${theme.colors.bgWhite};
`;

const StHeaderLeft = styled.div`
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StLogo = styled.img`
  width: 243px;
  height: 26px;
  margin-right: 62px;
`;

const StMenu = styled.img`
  width: 32px;
  height: 32px;
`;

const StHeaderRight = styled.div`
  margin-right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StAlert = styled.img`
  width: 32px;
  height: 32px;
`;

interface Props {
  onMenuClick: () => void;
}

const Header: React.FC<Props> = ({ onMenuClick }) => {
  const navigate = useNavigate();
  const isAlert = useAppSelector((state) => state.userInfo.data.noticeIdx);

  const goMain = () => {
    navigate("/");
  };

  const goPost = () => {
    navigate("/notice");
  };
  return (
    <StHeader>
      <StHeaderLeft>
        <StLogo
          src="/imgs/header/header_logo.png"
          alt="header_logo"
          onClick={goMain}
        />
        <StMenu
          src="/imgs/header/header_menu.png"
          alt="header_menu"
          onClick={onMenuClick}
        />
      </StHeaderLeft>
      <StHeaderRight>
        {isAlert.length === 0 ? (
          <StAlert
            onClick={goPost}
            src="/imgs/header/header_alert_none.png"
            alt="header_alert"
          />
        ) : (
          <StAlert
            onClick={goPost}
            src="/imgs/header/header_alert_active.png"
            alt="header_alert"
          />
        )}
      </StHeaderRight>
    </StHeader>
  );
};

export default Header;
