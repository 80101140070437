import axios, { AxiosPromise } from "axios";

interface Refresh {
  (): AxiosPromise;
}

const refresh: any = async () => {
  const refreshToken = window.sessionStorage.getItem("refreshToken");

  try {
    const response = await axios({
      url: "/auth/member/refresh_token",
      method: "get",
      headers: {
        accept: `application/json`,
      },
      params: {
        refresh_token: 1,
      },
    });

    window.sessionStorage.setItem(
      "accessToken",
      await response.data.data.accessToken
    );
    window.sessionStorage.setItem("idToken", await response.data.data.idToken);

    window.sessionStorage.setItem(
      "sessionToken",
      await response.data.data.session
    );
  } catch (error: any) {
    console.log("error", error);
    window.sessionStorage.clear();
    window.location.href = "/";
    alert("접속 시간이 만료 되었습니다 다시 로그인 해주세요");
    return error.response.data;
  }
};

export default refresh;
