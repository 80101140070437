import axios, { AxiosPromise } from "axios";
import refresh from "../auth/refresh/refresh";

interface UserInfo {
  (): AxiosPromise;
}

const userInfo: any = async () => {
  const idToken = window.sessionStorage.getItem("idToken");
  const accessToken = window.sessionStorage.getItem("accessToken");
  const sessionToken = window.sessionStorage.getItem("sessionToken");
  try {
    const response = await axios({
      url: "/account/member",
      method: "get",
      headers: {
        accept: `application/json`,
        Authorization: `Bearer ${idToken}`,
        "Access-Token": accessToken,
      },
    });

    return response.data;
  } catch (error: any) {
    const statusCode = error.response.data.statusCode;
    if (statusCode === 401) {
      await refresh();
      return userInfo();
    } else {
      return error.response.data;
    }
  }
};

export default userInfo;
