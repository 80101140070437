import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import AppLayout from "../Layout/AppLayout/AppLayout";

const StNotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StImg = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 32px;
`;

const StTitle = styled.h1`
  margin-bottom: 16px;
  font-weight: 700;
  line-height: 20px;
  font-size: 32px;
  letter-spacing: -0.02em;
  color: ${theme.colors.grayFont};
`;

const StPar = styled.div`
  width: 575px;
  height: 48px;
  margin-bottom: 40px;
  text-align: center;
  ${theme.font.regular.fontSize18};
`;

const StBtn = styled.button`
  padding: 10px 20px;
  border-radius: 5px;
  color: ${theme.colors.bgWhite};
  background: ${theme.colors.brandColor};
  ${theme.font.medium.fontSize18};
`;

const NotFound = () => {
  const naviagate = useNavigate();
  return (
    <AppLayout>
      <StNotFoundContainer>
        <StImg src="/imgs/notFound/error_404.png" alt="404_img" />
        <StTitle>요청하신 페이지를 찾을 수 없습니다.</StTitle>
        <StPar>
          주소가 잘못 입력되었거나, 변경 혹은 삭제되어 요청하신 페이지를 찾을 수
          없습니다. 입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.
        </StPar>
        <StBtn onClick={() => naviagate("/")}>메인으로 이동하기</StBtn>
      </StNotFoundContainer>
    </AppLayout>
  );
};

export default NotFound;
