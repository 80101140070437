import axios from "axios";

const login: any = async (id: string, pw: string) => {
  try {
    const response = await axios({
      url: "auth/member/login",
      method: "get",
      params: {
        id,
        password: pw,
      },
    });

    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

export default login;
