import axios, { AxiosPromise } from "axios";
import refresh from "../auth/refresh/refresh";
import changeDate from "../date/changeDate";

interface Info {
  page: number;
  type?: string;
  start?: string;
  end?: string;
  search?: string;
  sort?: "post_id" | "title" | "insert_dttm" | "update_dttm";
  sortOrder?: "asc" | "desc";
  pageLimit?: number;
}

interface ContactList {
  (info: Info, page: number): AxiosPromise;
}

const contactList: any = async (info: Info, page: number) => {
  const accessToken = window.sessionStorage.getItem("accessToken");
  const idToken = window.sessionStorage.getItem("idToken");

  try {
    const response = await axios({
      url: "/inquiry/member",
      method: "get",
      headers: {
        Accept: `application/json`,
        Authorization: `Bearer ${idToken}`,
        "Access-Token": accessToken,
      },
      params: {
        ...info,
        start: changeDate(info.start),
        end: changeDate(info.end),
        page: page,
      },
    });

    return response.data;
  } catch (error: any) {
    const statusCode = error.response.data.statusCode;
    if (statusCode === 401) {
      await refresh();
      return contactList(info, page);
    } else {
      return error.response.data;
    }
  }
};

export default contactList;
