import axios from "axios";
import { log } from "console";
import refresh from "../refresh/refresh";

const logout: any = async () => {
  const accessToken = window.sessionStorage.getItem("accessToken");
  const idToken = window.sessionStorage.getItem("idToken");

  try {
    const response = await axios({
      url: "/auth/member/logout",
      method: "get",
      headers: {
        Accept: `application/json`,
        Authorization: `Bearer ${idToken}`,
        "Access-Token": accessToken,
      },
    });

    window.sessionStorage.clear();
    window.location.href = "/";
    return response.data;
  } catch (error: any) {
    const statusCode = error.response.data.statusCode;
    if (statusCode === 401) {
      await refresh();
      return logout();
    } else {
      return error.response.data;
    }
  }
};

export default logout;
