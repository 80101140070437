import { ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

interface ModalProps {
  children?: ReactNode;
  portalName?: string;
}

const Modal = ({ children, portalName }: ModalProps) => {
  const el = useRef(document.createElement("div"));

  useEffect(() => {
    const current = el.current;
    const modalRoot = document.querySelector(`#${portalName}`) as HTMLElement;

    modalRoot!.appendChild(current);
    return () => void modalRoot!.removeChild(current);
  }, [portalName]);

  return createPortal(children, el.current);
};

export default Modal;
