import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import {
  StBtnContainer,
  StControllContainer,
  StControllItem,
  StControllSelect,
  StDateContainer,
  StDatePicker,
  StExcelBtn,
  StGraphBtn,
  StGraphBtnContainer,
  StGraphWord,
  StListContainer,
  StPeriodWord,
  StRightSection,
  StSearchButton,
  StServiceName,
  StTableItemContainer,
  StTalbleItem,
  StWave,
} from "../CommonStyled/ComonStyled";
import { ResponsiveLine } from "@nivo/line";
import changeDate from "../../services/date/changeDate";
import { useAppDispatch } from "../../redux/store/store";
import { alertAction } from "../../redux/features/modals/alertModal";
import service from "../../services/graph/service";
import { changeNum } from "../../services/common/changeCost";
import excelDownload from "../../services/graph/excelDownload";
import monthServiceTotal from "../../services/graph/monthServiceTotal";
import changeDateMonth from "../../services/date/chageDateMonth";
import { useNavigate } from "react-router-dom";
import monthServiceCostAll from "../../services/downloadExcel/monthServiceCostAll";

type GraphDataDay = {
  stdDt: string;
  value: number;
};

type GraphDataWeek = {
  week: string;
  stats: {
    stdDt: string;
    value: number;
  }[];
};

const StGraphContainer = styled.div`
  width: 1560px;
  min-height: 530px;
  margin-top: 40px;
  padding: 22px 24px 0 40px;
  border-radius: 16px;
  background: ${theme.colors.bgWhite};
`;

const StGraphTitle = styled.h2`
  margin-bottom: 16px;
  color: ${theme.colors.brandColor};
  ${theme.font.medium.fontSize20};
`;

const StGraph = styled.div`
  width: 1496px;
  height: 300px;
  border-radius: 0px;
  // overflow: scroll;
`;

const StTable = styled.div`
  margin-top: 30px;
  overflow: scroll;
`;

const MonthServiceTotalGraph = () => {
  const prevMonth = new Date(new Date().setDate(new Date().getMonth() - 1));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<any>(prevMonth);
  const [endDate, setEndDate] = useState<any>(new Date(Date.now()));
  const [info, setInfo] = useState({
    start: startDate,
    end: endDate,
  });

  const DATA: any = [];
  const [graphData, setGraphData] = useState([]);
  const [spread, setSpread] = useState(false);
  const [dataList, setDataList] = useState<string[]>([]);

  const onChangeDate = (type: string, date: any) => {
    if (type === "start") {
      setStartDate(date);
      setInfo({ ...info, [type]: date });
    } else {
      setEndDate(date);
      setInfo({ ...info, [type]: date });
    }
  };

  const getService = async () => {
    const { statusCode, data, message } = await monthServiceTotal(info);
    if (statusCode === 200) {
      setGraphData(data.spServiceList);
      setDataList(["Product", ...data.stdDtList]);
    } else {
      dispatch(alertAction.openModal({ modalName: "alert", text: message }));
    }
  };

  const gdata = graphData.map((item: any) => {
    const id = item.spServiceName;

    const data = item.stats.map((item: GraphDataDay) => {
      const x = item.stdDt;
      const y = item.value;
      return { x, y };
    });

    return { id, data };
  });

  const chart = graphData.map((item: any) => {
    const service = item.spServiceName;

    const data = item.stats.map((item: GraphDataDay) => {
      const x = item.stdDt;
      const y = item.value;
      return { x, y };
    });
    return { service, data };
  });

  const DonwLoad = async () => {
    const { statusCode, data, message } = await monthServiceCostAll(info);
    if (statusCode === 200) {
      const link = await data.downloadLink;
      window.location.href = link;
    } else {
      dispatch(alertAction.openModal({ modalName: "alert", text: message }));
    }
  };

  useEffect(() => {
    getService();
  }, []);

  return (
    <StGraphContainer>
      <StControllContainer>
        <StPeriodWord>조회기간</StPeriodWord>

        <StDateContainer>
          <StDatePicker
            dateFormat="yyyy-MM"
            showMonthYearPicker
            showFullMonthYearPicker
            selected={startDate}
            onChange={(date) => onChangeDate("start", date)}
            startDate={startDate}
            endDate={endDate}
          />
          <StWave src="/imgs/calendar/~.png" alt="~" />
          <StDatePicker
            dateFormat="yyyy-MM"
            showMonthYearPicker
            showFullMonthYearPicker
            selected={endDate}
            onChange={(date) => onChangeDate("start", date)}
            startDate={startDate}
            endDate={endDate}
          />
        </StDateContainer>

        <StRightSection>
          <StControllItem margin="0 16px 0 0">
            <StSearchButton onClick={getService}>조회</StSearchButton>
          </StControllItem>

          <li>
            <StSearchButton
              style={{
                background: `${theme.colors.black}`,
              }}
              onClick={() => navigate("/use/all")}
            >
              상세보기
            </StSearchButton>
          </li>
        </StRightSection>
      </StControllContainer>

      <StGraphTitle>서비스 별 사용 금액</StGraphTitle>

      <StGraph>
        <ResponsiveLine
          data={gdata}
          tooltip={({ point }) => {
            const data = point.data;
            return (
              <div
                style={{
                  background: "white",
                  padding: "9px 12px",
                  border: "1px solid #ccc",
                  borderRadius: "6px",
                  fontWeight: "bold",
                }}
              >
                <div>
                  <strong>날짜 :</strong> {data.xFormatted}
                </div>
                <div>
                  <strong>금액 :</strong> {data.yFormatted}
                </div>
              </div>
            );
          }}
          margin={{ top: 50, right: 200, bottom: 50, left: 60 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: false,
            reverse: false,
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            // legend: "transportation",
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            // legend: "count",
            legendOffset: -40,
            legendPosition: "middle",
          }}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </StGraph>
      <StGraphBtnContainer>
        <StExcelBtn onClick={DonwLoad}>엑셀 저장</StExcelBtn>
        <StGraphBtn
          onClick={() => {
            setSpread(!spread);
          }}
        >
          {!spread ? <div>펼쳐보기</div> : <div>닫기</div>}
          <img src="/imgs/graph/icon_listmenu.png" alt="icon" />
        </StGraphBtn>
      </StGraphBtnContainer>

      {spread && (
        <StTable>
          <StListContainer>
            {dataList.map((item, index) =>
              index === 0 ? (
                <StServiceName key={index}>{item}</StServiceName>
              ) : (
                <StTalbleItem key={index}>{item}</StTalbleItem>
              )
            )}
          </StListContainer>
          {chart.map((item, index) => {
            const service = item.service;

            const dataY = item.data.map((item: any) => {
              return item.y;
            });

            return (
              <StTableItemContainer key={index}>
                <StServiceName>{service}</StServiceName>

                {dataY.map((item: any, index: number) => (
                  <StTalbleItem key={index}>{changeNum(item)}</StTalbleItem>
                ))}
              </StTableItemContainer>
            );
          })}
        </StTable>
      )}
    </StGraphContainer>
  );
};

export default MonthServiceTotalGraph;
