import React, { useEffect, useState } from "react";
import { alertAction } from "../../redux/features/modals/alertModal";
import { useAppDispatch, useAppSelector } from "../../redux/store/store";
import userInfo from "../../services/user/userInfo";
import Invoice from "./Invoice";
import ScaleLoader from "react-spinners/ScaleLoader";
import PortalLayout from "../../components/PortalLayout/PortalLayout";

const InvoiceContainer = () => {
  const dispatch = useAppDispatch();

  const [userList, setUserList] = useState([]);

  const getUserInfo = async () => {
    const { statusCode, data, message } = await userInfo();

    if (statusCode === 200) {
      setUserList(data.service);
    } else {
      dispatch(alertAction.openModal({ modalName: "alert", text: message }));
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return userList.length !== 0 ? (
    <Invoice serviceList={userList} />
  ) : (
    <PortalLayout zIndex={100}>
      <ScaleLoader color="#7FC41C" height={100} width={10} />
    </PortalLayout>
  );
};

export default InvoiceContainer;
